import { initialState } from "./reducer";

const getStateData = (state: any) => state["campaigns"] || initialState;

export const getCampaignsList=(state:any)=>{
  const reducer = getStateData(state);
  return reducer.campaignsList;
}


export const getLoadingCampaignsList = (state: any) => {
  const reducer = getStateData(state);
  return reducer.loadingCampaignList;
};

export const getCampaignsListCount = (state: any) => {
  const reducer = getStateData(state);
  return reducer.campaignsListCount;
};

export const getCampaignLeadList=(state:any)=>{
  const reducer = getStateData(state);
  return reducer.campaignLeadList;
}

export const getLoadingCampaignLeadList = (state: any) => {
  const reducer = getStateData(state);
  return reducer.loadingCampaignLeadList;
};

export const getCampaignLeadListCount = (state: any) => {
  const reducer = getStateData(state);
  return reducer.campaignLeadListCount;
};

export const getCampaignActivityList=(state:any)=>{
  const reducer = getStateData(state);
  return reducer.campaignActivityList;
}

export const getLoadingCampaignActivityList = (state: any) => {
  const reducer = getStateData(state);
  return reducer.loadingCampaignActivityList;
};

export const getCampaignActivityListCount = (state: any) => {
  const reducer = getStateData(state);
  return reducer.campaignActivityListCount;
};

export const getTopSectionData = (state: any) => {
  const reducer = getStateData(state);
  return reducer.topSectionData;
};

export const getAttemptDetails=(state: any)=>{
  const reducer = getStateData(state);
  return reducer.attemptDetails;
}

export const getGraphData = (state: any) => {
  const reducer = getStateData(state);
  return reducer.graphData;
};

export const getCampaignsListDetail=(state:any)=>{
  const reducer = getStateData(state);
  return reducer.campaignListDetail;
}

export const getLoadingCampaignsListDetail = (state: any) => {
  const reducer = getStateData(state);
  return reducer.loadingCampaignListDetail;
};

export const getCampaignsListMore = (state: any) => {
  const reducer = getStateData(state);
  return reducer.isFetchingMore;
};

export const getCampaignsLeadListMore = (state: any) => {
  const reducer = getStateData(state);
  return reducer.iscampaignLeadListFetchingMore;
};

export const getHungUpdata=(state: any)=>{
  const reducer = getStateData(state);
  return reducer.hungUpData;
}
export const getHungUpDataLoading=(state: any)=>{
  const reducer = getStateData(state);
  return reducer.hungUpDataLoading;
}

export const getCampaignData=(state: any)=>{
  const reducer = getStateData(state);
  return reducer.campaignData;
}

export const getCallAttemptsLoading = (state: any) => {
  const reducer = getStateData(state);
  return reducer.callAttemptsLoading;
};
export const getCallAttemptsData = (state: any) => {
  const reducer = getStateData(state);
  return reducer.callAttemptsData;
}