import { getAPI, postAPI, putAPI } from "../../apis/apis";

export const fetchCampaignData = async (payload:any) => {
    return getAPI({
      url: process.env.REACT_APP_BASE_URL + `/admin/campaign/campaign/minimal-data?organisationId=${payload}`,
      headers: {},
    });
  };

  export const postFinanceCalculator = async (payload:any) => {
    return postAPI({
      url:
      `${process.env.REACT_APP_BASE_URL}/property-calculator/finance`,
      headers: {},
      data: payload,
    });
  };

  export const updateFinanceCalculator = async (payload:any,id:any) => {
    return putAPI({
      url:
      `${process.env.REACT_APP_BASE_URL}/property-calculator/finance/${id}`,
      headers: {},
      data: payload,
    });
  };
  export const getPropertyAISummary = (id: any,orgId:any, payload: any,type:any) => {
    return getAPI({
      url: `${process.env.REACT_APP_BASE_URL}/admin/campaign/lead/${id}/summary/${orgId}?skip=${payload.index}&count=${payload.size}`,
      headers: {}
    });
  };

  export const fetchNotes = async (id: any,orgId:any, pageNumber: number,count:number=5) => {
    return getAPI({
      url: `${process.env.REACT_APP_BASE_URL}/admin/campaign/lead/${id}/notes/${orgId}?skip=${pageNumber}&count=${count}`,
      headers: {},
    });
  };
  

  export const fetchNoteCount = async (id: any,orgId:any) => {
    return getAPI({
      url: `${process.env.REACT_APP_BASE_URL}/admin/campaign/aggregate/count/${id}/${orgId}`,
      headers: {},
    });
  };