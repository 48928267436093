

const Disconnected = ({ color }: { color?: any }) => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <g clipPath="url(#clip0_22257_143208)">
                    <path d="M4.10964 4.10817L15.893 15.8915M18.3346 9.99984C18.3346 14.6022 14.6037 18.3332 10.0013 18.3332C5.39893 18.3332 1.66797 14.6022 1.66797 9.99984C1.66797 5.39746 5.39893 1.6665 10.0013 1.6665C14.6037 1.6665 18.3346 5.39746 18.3346 9.99984Z"
                        stroke={color ? color : "#F04438"}
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round" />
                </g>
                <defs>
                    <clipPath id="clip0_22257_143208">
                        <rect width="20" height="20" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </>
    );
};

export default Disconnected;


