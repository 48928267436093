


const DNC = ({ color }: { color?: any }) => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M13.7513 13.3333L17.918 17.5M17.918 13.3333L13.7513 17.5M10.0013 12.9167H6.25131C5.08833 12.9167 4.50685 12.9167 4.03368 13.0602C2.96835 13.3834 2.13467 14.217 1.8115 15.2824C1.66797 15.7555 1.66797 16.337 1.66797 17.5M12.0846 6.25C12.0846 8.32107 10.4057 10 8.33464 10C6.26357 10 4.58464 8.32107 4.58464 6.25C4.58464 4.17893 6.26357 2.5 8.33464 2.5C10.4057 2.5 12.0846 4.17893 12.0846 6.25Z"
                    stroke={color ? color : "#F79009"}
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round" />
            </svg>
        </>
    );
};

export default DNC;