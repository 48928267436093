import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import { useCallback, useEffect, useState } from "react";
import styles from "./CallAttemptsNote.module.scss";

const CallAttemptsNote = ({
  onSend,
  detail,
  newAttempt,
  edit,
  template
}: {
  onSend: Function;
  detail?: any;
  newAttempt: boolean;
  edit?: any;
  template?:""
}) => {
  const [editorVisible, setEditorVisible] = useState(false);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const handleEditorStateChange = (newState: EditorState) => {
    setEditorState(newState);
    const rawContentState = convertToRaw(newState.getCurrentContent());
    const htmlContent = draftToHtml(rawContentState);
    
  };

  const handleEditMenuItemClick = useCallback((message: any) => {
    if (!message) return;
    const { contentBlocks, entityMap } = convertFromHTML(message);
    const newEditorState = EditorState.createWithContent(
      ContentState.createFromBlockArray(contentBlocks, entityMap)
    );
    handleEditorStateChange(newEditorState);
    setEditorVisible(true);
  }, []);

  const initializeEditorState = (data:any="") => {
    const blocksFromHTML = convertFromHTML(data);
    const contentState = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
    if(!detail){
     setEditorVisible(true);
    onSend(data);
    setEditorState(EditorState.createWithContent(contentState))}
  };

  useEffect(()=>{
    if(template){
    initializeEditorState(template)
  }
  },[template])
  useEffect(() => {
    if (edit) {
      if (detail?.summary) {
        handleEditMenuItemClick(detail?.summary);
      } else {
        setEditorState(EditorState.createEmpty());
      }
      setEditorVisible(true);
    }
  }, [edit, detail]);

  return (
    <div className={` ${styles.note}`}>
      {
        detail.summary ? (
          <div className={` ${styles.note__summary}`}>
            <div
              className={`dflex justifySpaceBetween alignCenter ${styles.note__header}`}
            >
              <h5>{detail?.createdByName}</h5>
              <p> {new Date(detail?.updatedAt).toLocaleString()}</p>
            </div>
            <div
              className={` ${styles.note__summaryText}`}
              dangerouslySetInnerHTML={{
                __html: detail.summary,
              }}
            ></div>
          </div>
        ) : (
          ""
        )
}
    </div>
  );
};
export default CallAttemptsNote;
