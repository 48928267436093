import { Column } from "../../models/table.interface";

export const CouponTableColumns = [
    {
      id: "coupon",
      label: "Coupon",
    
      minWidth: 30,
      type: "",
     
    },
    {
      id: "terms",
      label: "Terms",
      minWidth: 70,
      type: "",
    },
    { id: "redemptions", label: "Redemptions", minWidth: 70, type: "" },
    { id: "expires", label: "Expires", minWidth: 70, type: "" },
    { id: "products", label: "Products", minWidth: 70, type: "multiple" },
    { id: "customers", label: "Customers", minWidth: 70, type: "multiple" },
    { id: "deleteCoupon", label: "", minWidth: 70, type: "" },
    
  ];