import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  productList: {} as any,
  productListLoading:false,
  couponList: [] as any[],
  couponListLoading:false,
  customerListLoading:false,
  couponDetail:{},
  customerList: [] as any[],
  customerDetail:{}
};

const couponSlice = createSlice({
  name: "coupon",
  initialState,
  reducers: {
    setProductList: (state, { payload }) => {
      return { ...state, productList: payload };
    },
    setProductListLoading: (state, { payload }) => { 
        return { ...state, productListLoading: payload };
    },
    setCouponList: (state, { payload }) => {
      return { ...state, couponList: payload };
    },
    appendCouponList: (state, { payload }) => {
      return {
        ...state,
        couponList:[...state?.couponList, ...(payload ? payload : [])],
      }
      },
      setCouponListLoading: (state, { payload }) => { 
        return { ...state, couponListLoading: payload };
    },
    setCouponDetail: (state, { payload }) => { 
      return { ...state, couponDetail: payload };
  },
  setCustomerList: (state, { payload }) => {
    return { ...state, customerList: payload };
  },
  appendCustomerList: (state, { payload }) => {
    return {
      ...state,
      customerList:[...state?.customerList, ...(payload ? payload : [])],
    }
    },
    setCustomerListLoading: (state, { payload }) => { 
      return { ...state, customerListLoading: payload };
  },
  setCustomerDetail: (state, { payload }) => { 
    return { ...state, customerDetail: payload };
},
  },
});

export const { setProductList,setProductListLoading,setCustomerListLoading,setCustomerDetail,appendCustomerList,setCustomerList,appendCouponList,setCouponList,setCouponListLoading,setCouponDetail } = couponSlice.actions;

export default couponSlice.reducer;
