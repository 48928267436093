import { Column } from "../../models/table.interface";

export const columns: Column[] = [
    {
      id: "name",
      label: "Name",
      minWidth: 30,
      type: "name",
     
    },
    {
      id: "email",
      label: "Email",
      minWidth: 30,
      type: "MultipleValues",
     
    },
    {
      id: "availableCredit",
      label: "Available Credit",
      minWidth: 30,
      type: "",
     
    },
    {
        id: "date",
        label: "Created at",
        minWidth: 70,
        type: "date",
    },
    {
      id: "vaAccess",
      label: "VA Access",
      minWidth: 30,
      type: "Switch",
     
    },
      {
        id: "action",
        label: "",
        minWidth: 70,
        type: "",
      },
    
   
  ];