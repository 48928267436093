import * as yup from "yup";

export const createCouponSchema = yup.object({
  name: yup.string().trim().required("Coupon name is required").max(100, "Coupon name cannot exceed 100 characters"),
  code: yup.string().trim().required("Coupon code is required").max(100, "Coupon code cannot exceed 100 characters"),
  duration: yup
    .string()
    .oneOf(["once", "forever", "repeating"], "Duration is required")
    .required("Duration is required"),
  durationInMonths: yup
    .number()
    .transform((value, originalValue) => {
      return originalValue === "" ? undefined : value;
    })
    .typeError("Duration in months must be a valid number")
    .when("duration", {
      is: "repeating",
      then: (schema) => schema.required("Duration in months is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
  discountType: yup
    .string()
    .oneOf(["amount", "percentage"], "Discount Type is required")
    .required("Discount type is required"),
  amount: yup
    .number()
    .transform((value, originalValue) => {
      return originalValue === "" ? undefined : value;
    })
    .typeError("Discount amount must be a valid number")
    .when("discountType", {
      is: "amount",
      then: (schema) => schema.required("Discount amount is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
  percentOff: yup
    .number()
    .transform((value, originalValue) => {
      return originalValue === "" ? undefined : value;
    })
    .typeError("Percentage off must be a valid number")
    .min(1, "Percentage cannot be less than 1")
    .max(100, "Percentage cannot exceed 100")
    .when("discountType", {
      is: "percentage",
      then: (schema) => schema.required("Percentage off is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
  isProduct: yup.boolean(),
  products: yup
    .array()
    .of(yup.string().trim().required("Product name is required"))
    .test(
      "is-required-when-product",
      "At least one product must be selected",
      function (value) {
        const { isProduct } = this.parent;
        if (isProduct) {
          return Array.isArray(value) && value.length > 0;
        }
        return true;
      }
    ),
  isCustomer: yup.boolean(),
  customers: yup
    .array()
    .of(yup.string().trim().required("Customer name is required"))
    .test(
      "is-required-when-customer",
      "At least one customer must be selected",
      function (value) {
        const { isCustomer } = this.parent;
        if (isCustomer) {
          return Array.isArray(value) && value.length > 0;
        }
        return true;
      }
    ),
  dateRange: yup.boolean(),
  redeem_by: yup
    .string()
    .test(
      "is-required-when-date-range",
      "Redemption date is required when date range is enabled",
      function (value) {
        const { dateRange } = this.parent;

        if (dateRange && (!value || value.trim() === "")) {
          return this.createError({
            message: "Redemption date is required when date range is enabled",
          });
        }

        return true;
      }
    ),
  noOfTimesRedeem: yup.boolean(),
  maxRedemptions: yup
    .number()
    .transform((value, originalValue) => {
      return originalValue === "" ? undefined : value;
    })
    .typeError(
      "Number of times this coupon can be redeemed must be a valid number"
    )
    .when("noOfTimesRedeem", {
      is: true,
      then: (schema) =>
        schema.required(
          "Number of times this coupon can be redeemed is required"
        ),
      otherwise: (schema) => schema.notRequired(),
    }),
});
