import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
 userDetailList: [] as any[],
 loadingUserDetailList: false,
 userListCount:0,
 orgUserRoleList:{},
 pendingUserDetailList:[] as any[],
 loadingPendingUserDetailList: false,
};

const userDetailSlice = createSlice({
  name: "userDetail",
  initialState,
  reducers: {
    setUserDetailList: (state, { payload }) => {
      return {
        ...state,
        userDetailList: payload,
      };
    },
    setUserListCount: (state, { payload }) => {
      return {
        ...state,
        userListCount: payload,
      };
    },
    setLoadingUserDetailList: (state, { payload }) => {
      return {
        ...state,
        loadingUserDetailList: payload,
      };
    },
    setOrgUserRoleList:(state,{payload})=>{
      return {
        ...state,
        orgUserRoleList:payload
      }
    },
    setPendingUserDetailList: (state, { payload }) => {
      return {
        ...state,
        pendingUserDetailList: payload,
      };
    },
    setLoadingPendingUserDetailList: (state, { payload }) => {
      return {
        ...state,
        loadingPendingUserDetailList: payload,
      };
    },
  },
});

export const { setUserDetailList, setLoadingUserDetailList,setUserListCount,setOrgUserRoleList,setLoadingPendingUserDetailList,setPendingUserDetailList} =
userDetailSlice.actions;

export default userDetailSlice.reducer;
