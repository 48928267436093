

const NotInterested  = ({ color }: { color?: any }) => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M14.1656 1.6665V10.8332M18.3322 8.1665V4.33317C18.3322 3.39975 18.3322 2.93304 18.1506 2.57652C17.9908 2.26292 17.7358 2.00795 17.4222 1.84816C17.0657 1.6665 16.599 1.6665 15.6656 1.6665H6.76391C5.54601 1.6665 4.93706 1.6665 4.44522 1.88937C4.01174 2.08579 3.64332 2.40186 3.38327 2.80043C3.08821 3.25267 2.99562 3.85453 2.81043 5.05827L2.37453 7.89161C2.13027 9.47926 2.00815 10.2731 2.24374 10.8908C2.45052 11.4329 2.83945 11.8862 3.34384 12.1731C3.91851 12.4998 4.72168 12.4998 6.32802 12.4998H6.99891C7.46562 12.4998 7.69897 12.4998 7.87723 12.5907C8.03403 12.6706 8.16152 12.798 8.24141 12.9548C8.33224 13.1331 8.33224 13.3665 8.33224 13.8332V16.2783C8.33224 17.4132 9.25223 18.3332 10.3871 18.3332C10.6578 18.3332 10.9031 18.1738 11.013 17.9264L13.8137 11.625C13.941 11.3384 14.0047 11.1951 14.1054 11.09C14.1944 10.9971 14.3037 10.9261 14.4247 10.8825C14.5616 10.8332 14.7184 10.8332 15.0321 10.8332H15.6656C16.599 10.8332 17.0657 10.8332 17.4222 10.6515C17.7358 10.4917 17.9908 10.2368 18.1506 9.92316C18.3322 9.56664 18.3322 9.09993 18.3322 8.1665Z"
                    stroke={color ? color : "#00488D"}
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </>
    );
};

export default NotInterested;


