const Verified = ({ color }: { color?: any }) => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M7.4987 9.58314L9.16536 11.2498L12.9154 7.49981M16.6654 9.99981C16.6654 14.0902 12.2037 17.0651 10.5804 18.0122C10.3959 18.1198 10.3036 18.1737 10.1734 18.2016C10.0724 18.2232 9.925 18.2232 9.82396 18.2016C9.69378 18.1737 9.60153 18.1198 9.41704 18.0122C7.79367 17.0651 3.33203 14.0902 3.33203 9.99981V6.01448C3.33203 5.34822 3.33203 5.01509 3.441 4.72873C3.53726 4.47576 3.69368 4.25004 3.89675 4.07109C4.12661 3.86851 4.43853 3.75154 5.06237 3.5176L9.53053 1.84204C9.70378 1.77707 9.7904 1.74459 9.87952 1.73171C9.95856 1.72029 10.0388 1.72029 10.1179 1.73171C10.207 1.74459 10.2936 1.77707 10.4669 1.84204L14.935 3.5176C15.5589 3.75154 15.8708 3.86851 16.1006 4.07109C16.3037 4.25004 16.4601 4.47576 16.5564 4.72873C16.6654 5.01509 16.6654 5.34822 16.6654 6.01448V9.99981Z" stroke="url(#paint0_linear_22273_171870)"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round" />
                <defs>
                    <linearGradient id="paint0_linear_22273_171870" x1="3.33203" y1="1.72314" x2="19.4603" y2="14.7603" gradientUnits="userSpaceOnUse">
                        <stop stopColor={color ? color : "#72EDF2"} />
                        <stop offset="1" stopColor={color ? color : "#5151E5"} />
                    </linearGradient>
                </defs>
            </svg>
        </>
    );
};

export default Verified;

