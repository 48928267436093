export const DISCOUNT_TYPE_OPTIONS = [
  { value: "amount", label: "Amount" },
  { value: "percentage", label: "Percentage" }
];

export const COUPON_DURATION_TYPE_OPTIONS = [
  { value: "once", label: "Once" },
  { value: "forever", label: "Forever" },
  { value: "repeating", label: "Repeating" },

];