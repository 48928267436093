import { Column } from "../../models/table.interface";

export const columns: readonly Column[] = [
    {
      id: "lead",
      label: "Lead",
      minWidth: 260,
      type: "titleDescription",
    },
    {
      id: "phone",
      label: "Phone",
      minWidth: 170,
      type: "Phone",
    },
    {
      id: "phone_attempt_summary",
      label: "Summary",
      minWidth: 450,
      type: "clampedText",
    },
    {
      id: "status",
      label: "Status",
      minWidth: 70,
      type: "statusPill",
    },
    {
      id: "followUpDate",
      label: "Follow Up Date",
      minWidth: 170,
      type: "",
    },
    {
      id: "updatedAtDate",
      label: "Last Update",
      minWidth: 170,
      type: "",
    },
  ];

  