import { createAction } from "@reduxjs/toolkit";

export const fetchCampaignsList = createAction(
  "CAMPAIGNS_LIST",
  function prepare(data) {
    return { payload: data };
  }
);

export const fetchCampaignLeadsList = createAction(
  "CAMPAIGN_LEAD_LIST",
  function prepare(data) {
    return { payload: data };
  }
);

export const fetchCampaignActivityList = createAction(
  "CAMPAIGN_ACTIVITY_LIST",
  function prepare(data) {
    return { payload: data };
  }
);

export const fetchTopSectionData = createAction(
  "TOPSECTION",
  function prepare(data) {
    return { payload: data };
  }
);

export const fecthAttemptIdAction = createAction("GET_ATTEMPT_ID", function prepare(data) {
  return { payload: data };
});

export const graphAction = createAction("GRAPH", function prepare(data) {
  return { payload: data };
});

export const fetchCampaignsListDetail = createAction(
  "CAMPAIGNS_LIST_Detail",
  function prepare(data) {
    return { payload: data };
  }
);

export const fetchHungupData = createAction("fetchHungUp", function prepare(data) {
  return { payload: data };
});

export const fetchCampaignData = createAction(
  "CAMPAIGNS_ID_LIST",
  function prepare(data) {
    return { payload: data };
  }
);

export const fetchCallAttempts = createAction(
  "fetchCallAttempts",
  (data) => ({ payload: data })
);
