import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastType } from "../../../../molecules/toaster/enum";
import { addToast } from "../../../../molecules/toaster/toastSlice";
import Chat from "../../../../organisms/chat/Chat";
import { getPropertyDetailsData } from "../../selector";
import { fetchNotes } from "../../service";
import { useParams } from "react-router-dom";

const NotesTab = () => {
   const { orgId } = useParams();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [addNotesLoading, setAddNotesLoading] = useState(false);
  const [notes, setNotes] = useState<any[]>([]);
  const propertyDetails = useSelector((state) => getPropertyDetailsData(state));
  const [pageNumber, setPageNumber] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  
  const handleShowToast = (
    message: string,
    type: ToastType = ToastType.success
  ) => {
    dispatch(
      addToast({
        id: new Date().getTime(),
        message: message,
        type: type,
      })
    );
  };

  const getNotes = useCallback(
    async (id: string, pageNumber: number = 1) => {
      try {
        if (pageNumber === 1) {
          setIsLoading(true);
        }
        const res = await fetchNotes(id,orgId, pageNumber);
        if (res?.data?.totalCount || res?.data?.count) {
          setNotes((messages) => [
            ...(pageNumber === 1 ? [] : messages),
            ...(res?.data?.result || []),
          ]);
          setTotalCount(+(res?.data?.totalCount || res?.data?.count || 0));
        } else {
          setNotes(res.data);
        }
        setIsLoading(false);
      } catch (error: any) {
        setIsLoading(false);
        handleShowToast(
          error.message || "Something went wrong!",
          ToastType.error
        );
      }
    },
    [setNotes, setIsLoading,orgId]
  );
  useEffect(() => {
    if (propertyDetails?.leadId ) {
      getNotes(propertyDetails?.leadId , pageNumber);
    }
  }, [propertyDetails?.leadId, getNotes, pageNumber,orgId]);

  return (
    <Chat
      chatLoading={isLoading}
      sendLoading={addNotesLoading}
      messages={notes}
      title="My Notes"
      totalCount={totalCount}
      handleNextPage={() => setPageNumber((prev) => prev + 1)}
    />
  );
};
export default NotesTab;
