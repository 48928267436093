import { createAction } from "@reduxjs/toolkit";

export const fetchProductList = createAction(
  "PRODUCT_LIST",
  function prepare(data) {
    return { payload: data };
  }
);

export const fetchCouponList = createAction(
  "COUPON_LIST",
  function prepare(data) {
    return { payload: data };
  }
);

export const fetchCustomerList = createAction(
  "CUSTOMER_LIST",
  function prepare(data) {
    return { payload: data };
  }
);