import React, { useCallback, useEffect, useState } from "react";
import ConfirmationModal from "../../../../components/confirmationModal/ConfirmationModal";
import Button from "../../../../atoms/Button";
import CustomTable from "../../../../molecules/table/CustomTable";
import Loader from "../../../../atoms/Loader";
import {
  getLoadingUserDetail,
  getOrgUserRoleList,
  getPendingUserDetailList,
  getUserDetailList,
  getUserListCount,
} from "../../selector";
import { useDispatch, useSelector } from "react-redux";
import { columns } from "../../constants";
import { disableUser, enableUser, resetPasswordEmail, updateUserRole } from "../../service";
import { useParams } from "react-router-dom";
import { getProfileInfo } from "../../../profile/selector";
import myAuditLogs from "@ottoleads/ottoleads_log_aggregation";
import { sendActivityLog } from "../../../../organisms/auth/service";
import { setUserDetailList } from "../../reducer";
import { Tooltip } from "@mui/material";
import { PermissionListEnum } from "../../../rolesAndPermission/enum";
import { ToastType } from "../../../../molecules/toaster/enum";
import { addToast } from "../../../../molecules/toaster/toastSlice";
import { CustomError } from "../../../../models/customError.interface";
import { Close, ForwardToInbox } from "@mui/icons-material";
import Check from "../../../../assets/icons/check";
import Message from "../../../../assets/icons/message";
import styles from "../../UserDetail.module.scss";
import { fetchOrgUserRoleList, fetchPendingUserDetailList, fetchUserDetailList } from "../../sagaAction";
import { getOrganization } from "../../../organization/selector";

const ActiveOrgUser = ({showButton}:any) => {
  const dispatch = useDispatch();
  const { orgId } = useParams();
  const profileInfo = useSelector((state) => getProfileInfo(state));
  const roleList = useSelector((state) => getOrgUserRoleList(state));
  const [roleListOptions, setRoleListOptions] = useState([]);
  const tableLoading = useSelector((state) => getLoadingUserDetail(state));
  const total = useSelector((state) => getUserListCount(state));
  const userDetail = useSelector((state) => getUserDetailList(state));
  const [detail, setDetail] = useState<Record<string, any>[]>([]);
  // const [showButton, setShowButton] = useState(false);
  const [emailReset, setEmailReset] = useState("");
  const [currentUser, setCurrentUser] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [removeUserModal, setRemoveUserModal] = useState(false);
  const pendingUsers = useSelector((state) => getPendingUserDetailList(state));
  const orgDetail = useSelector((state) => getOrganization(state));
  const handleShowToast = (
    message: string,
    type: ToastType = ToastType.success
  ) => {
    dispatch(
      addToast({
        id: new Date().getTime(),
        message: message,
        type: type,
      })
    );
  };

  const handleStatusChange = async (list: any) => {
    const payload = {
      id: list.id,
      userRoleName: list?.value,
      isApprovedUser: list?.flag,
    };

    try {
      await updateUserRole(orgId,payload);
    } catch (err) {
      console.log(err);
    }

  };

  const logAuditData = async (data: any, logType: any) => {
    const attributes = {
      action: data
        ? `${data}`
        : `User is trying to disable organisation ${orgId}`,
      userId: profileInfo?.id,
      uuid: "99",
      email: profileInfo.email,
      roleName: profileInfo.roleName,
    };
    try {
      const result = await myAuditLogs(attributes);
      console.log(result);
      try {
        const res = await sendActivityLog({
          logType: logType
            ? `${logType}`
            : "User is trying to disable organisation",
          jsonData: result,
        });
        console.log(res);
      } catch (error) {
        console.error("Error logging audit data:", error);
      }
    } catch (error) {
      console.error("Error logging audit data:", error);
    }
  };

  const handleResetEmail = async (email: string) => {
    setEmailReset(email);
    try {
      await resetPasswordEmail(email);
      setEmailReset("");
      handleShowToast(
        "Reset password email sent successfully!",
        ToastType.success
      );
    } catch (error) {
      setEmailReset("");
      handleShowToast(
        (error as CustomError)?.response?.data?.message ||
          "Something went wrong!",
        ToastType.error
      );
    }
  };

  const handleRemoveUser = () => {
    setRemoveUserModal(!removeUserModal);
  };

  const handleDisableUser = async () => {
    setLoading(true);
    try {
      const response = await disableUser(currentUser);
      console.log(response);
      setLoading(false);
      const updatedList = userDetail.map((item: any) => {
        if (item.id === currentUser.toString()) {
          return { ...item, isSeatSubscriptionActive: false };
        }
        return item;
      });
      dispatch(setUserDetailList(updatedList));
      handleRemoveUser();
      handleShowToast("User disabled successfully!", ToastType.success);
      logAuditData(`User has disabled ${currentUser}`, "Disabled user");
      setCurrentUser(-1);
    } catch (error) {
      setLoading(false);
      handleShowToast(
        (error as CustomError)?.response?.data?.message ||
          "Something went wrong!",
        ToastType.error
      );
    }
  };

  const handleEnableUser = async (data: any) => {
    try {
      await enableUser(data);
      setLoading(false);
      const updatedList = userDetail.map((item: any) => {
        if (item.id === data.toString()) {
          return { ...item, isSeatSubscriptionActive: true };
        }
        return item;
      });
      dispatch(setUserDetailList(updatedList));
      handleShowToast("User enabled successfully!", ToastType.success);
      logAuditData(`User has enabled ${data}`, "Enabled user");
      setCurrentUser(-1);
    } catch (error) {
      setCurrentUser(-1);
      setLoading(false);
      handleShowToast(
        (error as CustomError)?.response?.data?.message ||
          "Something went wrong!",
        ToastType.error
      );
    }
  };

  useEffect(() => {
    if (!removeUserModal) {
      setCurrentUser(-1);
    }
  }, [removeUserModal]);

  const displayListData = useCallback(() => {
    const userListData = [ ...pendingUsers,...userDetail]?.map((list: any) => {
      return {
        id:list?.id,
        name: `${list?.nameFirst} ${list?.nameLast ? list?.nameLast : ""}`,
        email: list?.email,
        roleName:list?.userRoleName,
        rootUser:list?.isRootUser ? list?.isRootUser:false,
        flag:list?.status === "PENDING" ? false:true,
        removeUser:list?.status ==="PENDING" ? "": (
          <div className="flex align-center gap-10">
            <Tooltip
              title={
                "By clicking on this, you can send an email to the user."
              }
            >
            <a
              className={`flex icon button alignCenter ${styles.emilList}`}
              href={`mailto:${list.email}`}
              >
              <span className="button__icon">
                <Message />
              </span>
            </a>
              </Tooltip>
            <Tooltip
              title={
                "By clicking on this user will get an email to reset password."
              }
            >
              <div>
                <Button
                  key={list?.id}
                  className="icon"
                  label=""
                  action={() => handleResetEmail(list.email)}
                  prefix={<ForwardToInbox />}
                  disabled={emailReset === list.email}
                  clicked={emailReset === list.email}
                ></Button>
              </div>
            </Tooltip>
            <span className={`${styles.buttonSpace}`}>
              {userDetail?.length > 1 && !list?.isRootUser ? ( 
                !list?.isSeatSubscriptionActive || showButton ? (
                  <Tooltip
                    title={
                      profileInfo?.permissions?.includes(
                        PermissionListEnum.ENABLE_DISABLE_ORGANISATION_USER
                      )
                        ? ""
                        : "You don't have permission to access this."
                    }
                  >
                    <div>
                      <Button
                        key={list?.id}
                        className="green-light"
                        label="Enable"
                        action={() => {
                          setCurrentUser(list?.id);
                          handleEnableUser(list?.id);
                        }}
                        prefix={<Check />}
                        disabled={
                          currentUser != -1 ||
                          showButton ||
                          !profileInfo?.permissions?.includes(
                            PermissionListEnum.ENABLE_DISABLE_ORGANISATION_USER
                          ) ||(list?.userRoleName ==="VA" && !orgDetail.isVaSubscribed  )
                        }
                        clicked={currentUser === list?.id}
                      ></Button>
                    </div>
                  </Tooltip>
                ) : (
                  <Tooltip
                    title={
                      profileInfo?.permissions?.includes(
                        PermissionListEnum.ENABLE_DISABLE_ORGANISATION_USER
                      )
                        ? ""
                        : "You don't have permission to access this."
                    }
                  >
                    <div>
                      <Button
                        key={list?.id}
                        className="red-light"
                        label="Disable"
                        disabled={
                          !profileInfo?.permissions?.includes(
                            PermissionListEnum.ENABLE_DISABLE_ORGANISATION_USER
                          )
                        }
                        action={() => {
                          setCurrentUser(list?.id);
                          logAuditData(
                            `User is trying to disable ${list?.email} of organisation ${orgId}`,
                            "Disable user"
                          );
                          handleRemoveUser();
                        }}
                        prefix={<Close />}
                      ></Button>
                    </div>
                  </Tooltip>
                )
              ) : (
                ""
              )}
            </span>
          </div>
        ),
      };
    });
    setDetail(userListData ?? []);
  }, [userDetail, emailReset, currentUser, showButton, removeUserModal,pendingUsers]);

  useEffect(() => {
    if(Object.keys(roleList).length >0){
      const options = roleList.map((list: any) => ({
      value: list,
      label: list,
    }));
    setRoleListOptions(options);
  }
  }, [roleList]);

  useEffect(() => {
    dispatch(fetchOrgUserRoleList({}))
  }, []);

  useEffect(() => {
    displayListData();
  }, [userDetail, emailReset, currentUser, showButton, removeUserModal,pendingUsers]);

  useEffect(() => {
    dispatch(fetchUserDetailList(orgId));
    dispatch(fetchPendingUserDetailList(orgId));
  }, [orgId]);

  return (
    <>
      {tableLoading ? (
        <div className="table-loader">
          <Loader></Loader>
        </div>
      ) : (
        <div className={`${styles.userDetail} `}>
          <CustomTable
            rows={detail}
            columns={columns}
            search={""}
            tableStriped
            hasMore={detail?.length < total}
            options={roleListOptions}
            handleClickedPill ={handleStatusChange}
          ></CustomTable>

          <ConfirmationModal
            isOpen={removeUserModal}
            setIsOpen={setRemoveUserModal}
            buttonComponent={
              <Button
                label={"Yes, Disable"}
                className="red xl full"
                action={handleDisableUser}
                disabled={loading}
                clicked={loading}
              />
            }
          >
            <h2>{removeUserModal ? "Disable User" : "Disable Organisation"}</h2>
            <p>
              Are you sure you want to{" "}
              {removeUserModal
                ? "disable this user"
                : "disable this organisation"}
              ? <br />
              This action cannot be undone.
            </p>
          </ConfirmationModal>
        </div>
      )}
    </>
  );
};

export default ActiveOrgUser;
