

const DidNotPickUp = ({ color }: { color?: any }) => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M17.501 2.5L12.501 7.5M12.501 2.5L17.501 7.5M8.5235 11.5526C7.52219 10.5512 6.73153 9.41904 6.15153 8.21102C6.10164 8.10712 6.0767 8.05516 6.05753 7.98942C5.98943 7.75579 6.03835 7.46891 6.18003 7.27105C6.21989 7.21537 6.26752 7.16774 6.36278 7.07248C6.65412 6.78115 6.79979 6.63548 6.89503 6.48899C7.25419 5.93659 7.25419 5.22443 6.89503 4.67203C6.79979 4.52554 6.65412 4.37988 6.36278 4.08854L6.20039 3.92615C5.75752 3.48328 5.53609 3.26184 5.29827 3.14156C4.8253 2.90233 4.26675 2.90233 3.79378 3.14156C3.55596 3.26184 3.33453 3.48328 2.89166 3.92615L2.7603 4.05751C2.31895 4.49886 2.09827 4.71954 1.92973 5.01956C1.74271 5.35249 1.60825 5.86956 1.60938 6.25141C1.61041 6.59554 1.67716 6.83072 1.81067 7.30109C2.52814 9.82892 3.88187 12.2142 5.87185 14.2042C7.86184 16.1942 10.2471 17.5479 12.775 18.2654C13.2453 18.3989 13.4805 18.4657 13.8246 18.4667C14.2065 18.4678 14.7236 18.3333 15.0565 18.1463C15.3565 17.9778 15.5772 17.7571 16.0186 17.3158L16.1499 17.1844C16.5928 16.7415 16.8142 16.5201 16.9345 16.2823C17.1737 15.8093 17.1737 15.2508 16.9345 14.7778C16.8142 14.54 16.5928 14.3185 16.1499 13.8757L15.9875 13.7133C15.6962 13.4219 15.5505 13.2763 15.404 13.181C14.8516 12.8219 14.1395 12.8219 13.5871 13.181C13.4406 13.2763 13.2949 13.4219 13.0036 13.7133C12.9083 13.8085 12.8607 13.8562 12.805 13.896C12.6072 14.0377 12.3203 14.0866 12.0866 14.0185C12.0209 13.9994 11.9689 13.9744 11.865 13.9245C10.657 13.3445 9.52482 12.5539 8.5235 11.5526Z"
                    stroke={color ? color : "#7499F9"}
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round" />
            </svg>
        </>
    );
};

export default DidNotPickUp;
