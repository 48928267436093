import { initialState } from "./reducer";

const getStateData = (state: any) => state["coupon"] || initialState;

export const getProductList = (state: any) => {
    const reducer = getStateData(state);
    return reducer.productList;
  };

  export const getProductListLoading = (state: any) => {
    const reducer = getStateData(state);
    return reducer.productListLoading;
  };

  export const getCouponList = (state: any) => {
    const reducer = getStateData(state);
    return reducer.couponList;
  };

  export const getCouponListLoading = (state: any) => {
    const reducer = getStateData(state);
    return reducer.couponListLoading;
  };

  export const getCouponDetail = (state: any) => {
    const reducer = getStateData(state);
    return reducer.couponDetail;
  };
  
  export const getCustomerList= (state: any) => {
    const reducer = getStateData(state);
    return reducer.customerList;
  };

  export const getCustomerListLoading= (state: any) => {
    const reducer = getStateData(state);
    return reducer.customerListLoading;
  };

  export const getCustomerDetail = (state: any) => {
    const reducer = getStateData(state);
    return reducer.customerDetail;
  };