import React, { useEffect, useMemo, useState } from "react";
import { amortizationCalculatorSchema } from "../../validation";
import {
  amortizationScheduleColumns,
  ProfitMarginOptions,
} from "../../columnsData";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import {
  getamortizationCalculatorList,
  getamortizationCalculatorloading,
  getPropertyDetailsData,
  getWholesaleCalculatorList,
  getWholesaleCalculatorloading,
} from "../../selector";
import ShadowBox from "../../../../components/shadowBox/ShadowBox";
import { formatNewCurrency } from "../../../../utils/utils";
import Input from "../../../../atoms/Input";
import DropDown from "../../../../atoms/Dropdown";
import CustomTable from "../../../../molecules/table/CustomTable";
import {
  fetchAmortizationCalculatorAction,
  fetchWholesaleCalculatorAction,
} from "../../sagaAction";
import { useParams } from "react-router-dom";
import { Schedule } from "../../property.type";
import CreativeFinance from "../creativeFinance/CreativeFinance";
import styles from "./Calculator.module.scss";
import CurrencyDollar from "../../../../assets/icons/currencyDoller";

function calculatePaymentPerPeriod(
  principal: number,
  annualInterestRate: number,
  totalYears: number,
  isMonthly: boolean
) {
  const periodsPerYear = isMonthly ? 12 : 1;
  const totalPeriods = totalYears * periodsPerYear;
  const periodInterestRate = annualInterestRate / periodsPerYear;
  const paymentPerPeriod =
    principal *
    (periodInterestRate /
      (1 - Math.pow(1 + periodInterestRate, -totalPeriods)));
  if (!isFinite(paymentPerPeriod) || isNaN(paymentPerPeriod)) {
    return 0;
  }

  return paymentPerPeriod;
}

const calculateAmortizationSchedule = (
  principal: number,
  annualInterestRate: number,
  totalYears: number,
  isMonthly: boolean
) => {
  const periodsPerYear = isMonthly ? 12 : 1;
  const totalPeriods = totalYears * periodsPerYear;
  const periodInterestRate = annualInterestRate / periodsPerYear;
  const paymentPerPeriod = calculatePaymentPerPeriod(
    principal,
    annualInterestRate,
    totalYears,
    isMonthly
  );

  const generateSchedule = (
    period: number,
    balance: number,
    schedule: Schedule[]
  ): Schedule[] => {
    if (period > totalPeriods || balance <= 0) {
      return schedule;
    }

    const interestForPeriod = balance * periodInterestRate;
    let principalForPeriod = paymentPerPeriod - interestForPeriod;
    // Preventing principalForPeriod from going negative due to rounding in the final period
    principalForPeriod =
      principalForPeriod > balance ? balance : principalForPeriod;
    const endingBalance = balance - principalForPeriod;

    schedule.push({
      period: period,
      totalPayment: parseFloat(paymentPerPeriod.toFixed(2)),
      interest: parseFloat(interestForPeriod.toFixed(2)),
      principal: parseFloat(principalForPeriod.toFixed(2)),
      endingBalance: parseFloat(endingBalance.toFixed(2)),
    });

    return generateSchedule(period + 1, endingBalance, schedule);
  };

  return generateSchedule(1, principal, []);
};

const Calculator = () => {
  const dispatch = useDispatch();
  const { orgId, id } = useParams();
  const {
    formState: { errors, isValid, isDirty },
    register,
    watch,
    control,
    setValue,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      afterRepairValue: 0,
      costOfRepairs: 0,
      assignmentFee: 0,
      loanAmount: 0,
      loanTerm: "",
      interestRate: "",
      profitMargin: ProfitMarginOptions[0].value,
    },
    resolver: yupResolver(amortizationCalculatorSchema),
  });
  const propertyDetails = useSelector((state) => getPropertyDetailsData(state));
  const {
    propertyId,
    lot, longitude ,latitude
  } = propertyDetails;
  const Profitmargin = watch("profitMargin");

  const afterRepairValue = parseFloat(
    (watch("afterRepairValue") ?? "").toString().replace(/,/gi, "")
  );
  const costOfRepairs = parseFloat(
    (watch("costOfRepairs") ?? "").toString().replace(/,/g, "")
  );
  const assignmentFee = parseFloat(
    (watch("assignmentFee") ?? "").toString().replace(/,/g, "")
  );

  const [wholesaleLoading, setWholesaleLoading] = useState(false);
  const [wholesaleFailLoading, setWholesaleFailLoading] = useState(false);
  const [amortizationFailLoading, setAmortizationFailLoading] = useState(false);
  const [amortizationLoading, setAmortizationLoading] = useState(false);

  const amortizationCalculatorloading = useSelector((state) =>
    getamortizationCalculatorloading(state)
  );
  const wholesaleCalculatorloading = useSelector((state) =>
    getWholesaleCalculatorloading(state)
  );

  const wholesaleCalculatorData = useSelector((state) =>
    getWholesaleCalculatorList(state)
  );
  const amortizationsaleCalculatorData = useSelector((state) =>
    getamortizationCalculatorList(state)
  );

  const maxAllowableOffer = useMemo(() => {
    if (
      afterRepairValue == null ||
      costOfRepairs == null ||
      assignmentFee == null ||
      !Profitmargin
    ) {
      return 0;
    }
    return afterRepairValue * Profitmargin - costOfRepairs - assignmentFee;
  }, [afterRepairValue, costOfRepairs, assignmentFee, Profitmargin]);

  const loanAmount = parseFloat(
    (watch("loanAmount") ?? "").toString().replace(/,/g, "")
  );

  const loanTerm = parseFloat(
    (watch("loanTerm") ?? "").toString().replace(/,/g, "")
  );
  const interestRate = parseFloat(
    (watch("interestRate") ?? "").toString().replace(/,/g, "")
  );

  const monthlyPayment = useMemo(() => {
    if (
      isNaN(loanAmount) ||
      loanTerm == null ||
      interestRate == null ||
      !isValid
    ) {
      return;
    }
    return calculatePaymentPerPeriod(
      loanAmount,
      interestRate / 100,
      loanTerm,
      true
    );
  }, [loanAmount, loanTerm, interestRate, isValid]);

  const amortizationSchedule = useMemo(() => {
    if (
      !loanAmount ||
      !loanTerm ||
      loanTerm > 60 ||
      !interestRate ||
      !isValid
    ) {
      return [];
    }
    return calculateAmortizationSchedule(
      loanAmount,
      interestRate / 100,
      loanTerm,
      false
    );
  }, [loanAmount, loanTerm, interestRate, isValid]);

  useEffect(() => {
    const payload = {
      orgId,
      propertyId: propertyId,
    };

    dispatch(fetchWholesaleCalculatorAction(payload));
    dispatch(fetchAmortizationCalculatorAction(payload));
  }, [orgId, propertyId]);

  useEffect(() => {
    setWholesaleLoading(wholesaleCalculatorloading);
    if (wholesaleCalculatorData) {
      const { afterRepairValue, costOfRepairs, assignmentFee, profitMargin } =
        wholesaleCalculatorData;
      if (afterRepairValue || afterRepairValue === 0) {
        setValue("afterRepairValue", wholesaleCalculatorData.afterRepairValue, {
          shouldTouch: true,
        });
      } else {
        setValue("afterRepairValue", null, { shouldTouch: false });
      }
      if (costOfRepairs || costOfRepairs === 0) {
        setValue("costOfRepairs", wholesaleCalculatorData.costOfRepairs, {
          shouldTouch: true,
        });
      } else {
        setValue("costOfRepairs", null, { shouldTouch: false });
      }
      if (assignmentFee || assignmentFee === 0) {
        setValue("assignmentFee", wholesaleCalculatorData.assignmentFee, {
          shouldTouch: true,
        });
      } else {
        setValue("assignmentFee", null, { shouldTouch: false });
      }
      if (profitMargin) {
        setValue(
          "profitMargin",
          parseFloat(wholesaleCalculatorData.profitMargin),
          { shouldTouch: true }
        );
      }
    }
  }, [wholesaleCalculatorData, setValue, wholesaleCalculatorloading]);

  useEffect(() => {
    setAmortizationLoading(amortizationCalculatorloading);
    if (amortizationsaleCalculatorData) {
      console.log(
        "amortizationsaleCalculatorData",
        amortizationsaleCalculatorData
      );

      const { loanAmount, interestRate, loanTerm } =
        amortizationsaleCalculatorData;

      if (loanAmount || loanAmount === 0) {
        setValue("loanAmount", loanAmount, { shouldTouch: true });
      } else {
        setValue("loanAmount", null, { shouldTouch: false });
      }
      if (interestRate || interestRate === 0) {
        setValue("interestRate", interestRate.toString(), {
          shouldValidate: true,
          shouldTouch: true,
        });
      } else {
        setValue("interestRate", "", { shouldTouch: false });
      }
      if (loanTerm || loanTerm === 0) {
        setValue("loanTerm", loanTerm.toString(), {
          shouldValidate: true,
          shouldTouch: true,
        });
      } else {
        setValue("loanTerm", "", { shouldTouch: false });
      }
    }
  }, [amortizationsaleCalculatorData, setValue, amortizationCalculatorloading]);
  return (
    <div className={` ${styles.calculator}`}>
         <div className={` ${styles.calculator__top}`}>
        <ShadowBox
          title="Wholesale Calculator"
          subTitle="Easily make the right wholesale offer with confidence."
          rightTitle="Max Allowable Offer (MAO):"
          rightSubTitle={
            maxAllowableOffer || maxAllowableOffer === 0
              ? formatNewCurrency(maxAllowableOffer)
              : "-"
          }
          showstatus
          loading={wholesaleLoading}
          failLoading={wholesaleFailLoading}
        >
          <div className={`dflex ${styles.calculator}`}>
            <div className={` ${styles.calculator__colFour}`}>
              <Input
                type="number"
                label="After Repair Value (ARV)*"
                register={register}
                name="afterRepairValue"
                placeholder="Enter the ARV"
                errors={errors}
                 prefix={<CurrencyDollar />}
                control={control}
                readOnly
              ></Input>
            </div>
            <div className={` ${styles.calculator__colFour}`}>
              <Input
                type="number"
                label="Cost of Repairs*"
                register={register}
                name="costOfRepairs"
                placeholder="Enter the repairs"
                errors={errors}
                // prefix={<CurrencyDollar />}
                control={control}
                readOnly
              ></Input>
            </div>
            <div className={` ${styles.calculator__colFour}`}>
              <Input
                type="number"
                label="Assignment Fee*"
                register={register}
                name="assignmentFee"
                placeholder="Enter the assignment fee"
                errors={errors}
                // prefix={<CurrencyDollar />}
                control={control}
                readOnly
              ></Input>
            </div>
            <div className={` ${styles.calculator__colFour}`}>
              <DropDown
                options={ProfitMarginOptions}
                label="Profit Margin*"
                control={control}
                name="profitMargin"
                errors={errors}
                placeholder="70%"
                defaultValue={ProfitMarginOptions[0].value}
                disabled
              ></DropDown>
            </div>
          </div>
        </ShadowBox>
        <ShadowBox
          title="Amortization Calculator"
          subTitle="Modify the values to quickly analyze a deal."
          rightTitle="Monthly Payment:"
          rightSubTitle={
            monthlyPayment || monthlyPayment === 0
              ? formatNewCurrency(monthlyPayment)
              : "-"
          }
          loading={amortizationLoading}
          showstatus
          failLoading={amortizationFailLoading}
        >
          <div className={`dflex ${styles.calculator}`}>
            <div className={` ${styles.calculator__col}`}>
              <Input
                type="number"
                label="Loan Amount*"
                register={register}
                name="loanAmount"
                placeholder="Enter the loan amount"
                errors={errors}
                // prefix={<CurrencyDollar />}
                // control={control}
                readOnly
              ></Input>
            </div>
            <div className={` ${styles.calculator__col}`}>
              <Input
                type="number"
                label="Loan Term*"
                register={register}
                name="loanTerm"
                placeholder="Enter number of years"
                errors={errors}
                min={0}
                readOnly
              ></Input>
            </div>
            <div className={` ${styles.calculator__col}`}>
              <Input
                type="number"
                label="Interest Rate*"
                register={register}
                name="interestRate"
                placeholder="Enter the interest rate"
                errors={errors}
                // prefix={<Percent />}
                readOnly
              ></Input>
            </div>
          </div>
          {amortizationSchedule.length !== 0 && (
            <div className="calculator-table">
              {" "}
              <CustomTable
                rows={amortizationSchedule}
                columns={amortizationScheduleColumns}
                tableStriped
              />
            </div>
          )}
        </ShadowBox>
        </div>
          <CreativeFinance />
    </div>
  );
};

export default Calculator;
