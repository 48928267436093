import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  logAttemptList: [] as any[],
  logAttemptListLoading: false,
  logAttemptListCount:0,
};

const logAttemptSlice = createSlice({
  name: "logAttempt",
  initialState,
  reducers: {
    setLogAttemptList: (state, { payload }) => {
      return {
        ...state,
        logAttemptList: payload,
      };
    },
    setLogAttemptListLoading: (state, { payload }) => {
      return {
        ...state,
        logAttemptListLoading: payload,
      };
    },
    setLogAttemptListCount: (state, { payload }) => {
      return {
        ...state,
        logAttemptListCount: payload,
      };
    },
    appendLogAttemptList:(state,{payload})=>{
      return {
        ...state,
        logAttemptList:[...state?.logAttemptList, ...(payload ? payload : [])],
      }
    },
    
  },
});

export const { setLogAttemptList, setLogAttemptListLoading ,appendLogAttemptList,setLogAttemptListCount} =
logAttemptSlice.actions;

export default logAttemptSlice.reducer;
