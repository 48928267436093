const CommentIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
      >
        <path
          d="M18.3333 6.66667H13C10.1997 6.66667 8.79961 6.66667 7.73005 7.21163C6.78924 7.691 6.02433 8.4559 5.54497 9.39671C5 10.4663 5 11.8664 5 14.6667V23.3333C5 24.8833 5 25.6583 5.17037 26.2941C5.63271 28.0196 6.98044 29.3673 8.7059 29.8296C9.34174 30 10.1167 30 11.6667 30V33.8925C11.6667 34.7806 11.6667 35.2246 11.8487 35.4527C12.007 35.651 12.2471 35.7664 12.5009 35.7661C12.7927 35.7658 13.1395 35.4884 13.8329 34.9337L17.8087 31.7531C18.6209 31.1033 19.0269 30.7784 19.4791 30.5474C19.8803 30.3425 20.3074 30.1927 20.7487 30.1021C21.2461 30 21.7662 30 22.8062 30H25.3333C28.1336 30 29.5337 30 30.6033 29.455C31.5441 28.9757 32.309 28.2108 32.7884 27.27C33.3333 26.2004 33.3333 24.8003 33.3333 22V21.6667M33.5355 6.46447C35.4882 8.41709 35.4882 11.5829 33.5355 13.5355C31.5829 15.4882 28.4171 15.4882 26.4645 13.5355C24.5118 11.5829 24.5118 8.41709 26.4645 6.46447C28.4171 4.51184 31.5829 4.51184 33.5355 6.46447Z"
          stroke="url(#paint0_linear_21497_46234)"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear_21497_46234"
            x1="5"
            y1="5"
            x2="35.7564"
            y2="34.9905"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#72EDF2" />
            <stop offset="1" stopColor="#5151E5" />
          </linearGradient>
        </defs>
      </svg>
    </>
  );
};

export default CommentIcon;
