import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useForm } from "react-hook-form";

import styles from "./CallAttempts.module.scss";
import AddedCallAttempts from "../addedCallAttempts/AddedCallAttempts";
import Disconnected from "../../assets/icons/disconnected";
import WrongNumber from "../../assets/icons/wrongNumber";
import DidNotPickUp from "../../assets/icons/didNotPickUp";
import HangUp from "../../assets/icons/hungUp";
import NotInterested from "../../assets/icons/notInterested ";
import DNC from "../../assets/icons/dnc";
import CallBackLater from "../../assets/icons/callBackLater";
import Verified from "../../assets/icons/verified";
import VoiceMail from "../../assets/icons/voiceMail";
import Loader from "../../atoms/Loader";
import { ContactInfoItemType } from "../propertyDetail/property.type";
import { callAttemptsOptions } from "../logAttempts/constants";
import { getCallAttemptsData, getCallAttemptsLoading } from "../campaignDetails/selector";
import { getPropertyDetailsData } from "../propertyDetail/selector";
import { fetchCallAttempts } from "../campaignDetails/sagaAction";

export const CALL_ATTEMPT_STATUS_ICONS = {
  LeftVoiceMail: <VoiceMail />,
  Disconnected: <Disconnected />,
  WrongNumber: <WrongNumber />,
  DoNotPickUp: <DidNotPickUp />,
  HungUp: <HangUp />,
  NotInterested: <NotInterested />,
  DNC: <DNC />,
  CallBackLater: <CallBackLater />,
  Verified: <Verified />,
};

const callAttemptsStatusOptions = Object.entries(callAttemptsOptions).map(
  ([key, value]) => ({
    label: key,
    value: value,
    prefix: CALL_ATTEMPT_STATUS_ICONS[value],
  })
);

const CallAttempts = () => {
  const {
    formState: { errors },
    control,
    setValue,
    getValues
  } = useForm({
    mode: "onSubmit",
  });
  const dispatch = useDispatch();
  const propertyDetails = useSelector((state) => getPropertyDetailsData(state));
  const callAttemptData = useSelector((state) => getCallAttemptsData(state));
  const callAttemptLoading = useSelector((state) =>
    getCallAttemptsLoading(state)
  );
  const [selectedStatuses, setSelectedStatuses] = useState<{ [key: string]: string }>({});

  const contactInfo = useMemo(() => {
    const phNumber = propertyDetails?.phones
      ? [...propertyDetails?.phones]
      : [];
    phNumber.sort((a: any, b: any) => {
      if ((a?.priority || 0) > (b?.priority || 0)) {
        return 1;
      } else {
        return -1;
      }
    });
    return phNumber as ContactInfoItemType[];
  }, [propertyDetails?.phones]);

  useEffect(() => {
    dispatch(fetchCallAttempts(propertyDetails.leadId));
  }, [propertyDetails.leadId]);

  useEffect(() => {
    if (callAttemptData?.lastCallAttempt > 0) {
      const lastAttemptLog =
      callAttemptData.callAttemptLogs.find(
        (log:any) => log.attempt === callAttemptData.lastCallAttempt
      );
      if (lastAttemptLog) {
        contactInfo.forEach((item) => {
          const matchingCall = lastAttemptLog.callAttempts.find(
            (call: any) => call.phoneNumber === item.number
          );
          if (matchingCall) {
            setValue(`status_${item.id}`, matchingCall.status);
            setSelectedStatuses((prev) => ({
              ...prev,
              [`status_${item.id}`]: matchingCall.status,
            }));
          }
        });
      }
    }
  }, [callAttemptData, contactInfo, setValue]);  
  return (
    <>
      {callAttemptLoading ? (
        <div className={`loader-main`}>
          <Loader></Loader>
        </div>
      ) : (
        <div className={` ${styles.call}`}>
            {callAttemptData?.lastCallAttempt > 0 && (
              <AddedCallAttempts
                attemptsDetail={callAttemptData.callAttemptLogs}
                callAttemptsStatusOptions={callAttemptsStatusOptions}
                control={control}
                errors={errors}
                getValues={getValues}
                setValue={setValue}
              />
            )}
          </div>
      )}
    </>
  );
};

export default CallAttempts;
