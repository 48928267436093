

const VoiceMail = ({ color }: { color?: any }) => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M5.0013 13.3332L15.0013 13.3332M5.0013 13.3332C6.84225 13.3332 8.33464 11.8408 8.33464 9.99984C8.33464 8.15889 6.84225 6.6665 5.0013 6.6665C3.16035 6.6665 1.66797 8.15889 1.66797 9.99984C1.66797 11.8408 3.16035 13.3332 5.0013 13.3332ZM15.0013 13.3332C16.8423 13.3332 18.3346 11.8408 18.3346 9.99984C18.3346 8.15889 16.8423 6.6665 15.0013 6.6665C13.1604 6.6665 11.668 8.15889 11.668 9.99984C11.668 11.8408 13.1604 13.3332 15.0013 13.3332Z"
                    stroke={color ? color : "#E478FA"}
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </>
    );
};

export default VoiceMail;


