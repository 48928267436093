import { useSelector } from "react-redux";
import InfoCard from "../../../../components/infoCard/InfoCard";
import ShadowBox from "../../../../components/shadowBox/ShadowBox";
import { formartNumberAsLocalString, formatCurrency, formatMonthYear } from "../../../../utils/utils";
import styles from "./PropertyDetailTab.module.scss";
import { getPropertyDetailsData } from "../../selector";
import EditIcon from "../../../../assets/icons/editIcon";
import { useEffect, useState } from "react";
import { LinearProgress, linearProgressClasses, styled } from "@mui/material";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 4,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 300 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 4,
    background: theme.palette.mode === "light" ? "#005DA4" : "#005DA4",
  },
}));

const SectionItem = ({ title, value }: { title: string; value: string }) => {
  if (typeof value === "boolean") {
    value = value ? "Yes" : "No";
  }

  if (typeof value === "number") {
    value = formartNumberAsLocalString(value);
  }

  value = Boolean(value) ? value : "-";
  return (
    <div className={`${styles.row__col}`}>
      <InfoCard title={title} description={value} />
    </div>
  );
};

const PropertyDetailTab = () => {
  const propertyDetails = useSelector((state) => getPropertyDetailsData(state));  
  const {
    owner,
    propertyInformation,
    lot,
    ownerInfo,
    currentMortgages,
    intel,
    ownerOccupied,
    absenteeOwner,
    tax,
    mlsActive,
    mlsPending,
    mlsHistory,
    demographics,
    valuation,
    landInformation,legal,generalInfo
  } = propertyDetails;

 const [ownerFullName, setOwnerFullName] = useState("");
  
  const sections = {
    "Ownership Information": [
      {
        title: "Owner 1 Name",
        value: ownerInfo?.ownerNames?.[0]?.fullName,
      },
      {
        title: "Owner 2 Name",
        value: ownerInfo?.ownerNames?.[1]?.fullName,
      },
      {
        title: "Length of Ownership",
        value:ownerInfo?.residencePeriodInYear && ownerInfo?.residencePeriodInYear!=0 ? 
        (ownerInfo?.residencePeriodInYear)>=1?Math.round(ownerInfo?.residencePeriodInYear) + ` ${Math.round(ownerInfo?.residencePeriodInYear/12)>1?'Years':'Year'}`  :
        ownerInfo?.residencePeriodInYear + ` ${ownerInfo?.residencePeriodInYear>1?'Months':'Month'}` 
        : "",
      },
      {
        title: "Mailing Address",
        value:  ownerInfo?.mailingAddress,
      },
    ],
    "Property Information": [
      {
        title: "Bedrooms",
        value: propertyInformation?.bedroomCount ? propertyInformation?.bedroomCount : "",
      },
      {
        title: "Full Bathrooms",
        value: propertyInformation?.bathroomCount ? propertyInformation?.bathroomCount : "",
      },
      {
        title: "Partial Bathrooms",
        value:  propertyInformation?.partialBathroomCount ? propertyInformation?.partialBathroomCount : "",
      },
      {
        title: "Living Area",
        value: propertyInformation?.livingAreaSquareFeet
        ? formartNumberAsLocalString(propertyInformation?.livingAreaSquareFeet) +
          " SqFt."
        : ""
      },
      {
        title: "Stories",
        value: propertyInformation?.storyCount ? propertyInformation?.storyCount : "",
      },
      {
        title: "Property Use",
        value:  generalInfo?.propertyTypeCategory ,
      },
      {
        title: "Residential Units",
        value: propertyInformation?.residentialUnitCount !=0 ? propertyInformation?.residentialUnitCount : "",
      },
      {
        title: "Basement",
        value: propertyInformation?.basementType,
      },
      {
        title: "Basement Size",
        value: propertyInformation?.basementSquareFeet
        ? formartNumberAsLocalString(propertyInformation?.basementSquareFeet) +
          " SqFt."
        : "",
      },
      {
        title: "Parking Spaces",
        value:  propertyInformation?.garageParkingSpaceCount ? propertyInformation?.garageParkingSpaceCount : "",
      },
      {
        title: "Fireplaces",
        value:  propertyInformation?.fireplaceCount ? propertyInformation?.fireplaceCount : "",
      },
      {
        title: "Air Conditioning",
        value: propertyInformation?.airConditioningSource,
      },
      {
        title: "Heating",
        value: propertyInformation?.heatSource,
      },
      {
        title: "Heating Fuel",
        value: propertyInformation?.heatingFuelType,
      },
      {
        title: "Water Source",
        value: propertyInformation?.waterService,
      },
      {
        title: "Garage",
        value: propertyInformation?.garage,
      },
      {
        title: "Garage Size",
        value:  propertyInformation?.garageSquareFeet && propertyInformation?.garageSquareFeet!=0
        ? formartNumberAsLocalString(propertyInformation?.garageSquareFeet) +
          " SqFt."
        :
         "-",
      },
      {
        title: "Carport",
        value: "-",
      },
    
      {
        title: "Pool",
        value: propertyInformation?.pool,
      },
    ],
    "Land/Location Information": [
      {
        title: "Lot Size",
        value: lot?.lotSizeAcres && lot?.lotSizeAcres !=0
        ? formartNumberAsLocalString(lot?.lotSizeAcres) + " acres"
        : "",
      },
      {
        title: "Lot Area",
        value: lot?.lotSizeSquareFeet && lot?.lotSizeSquareFeet !=0
        ? formartNumberAsLocalString(lot?.lotSizeSquareFeet) + " sqft"
        : "",
      },
      {
        title: "Property Class",
        value: propertyInformation?.buildingClass ? propertyInformation?.buildingClass : "-",
      },
      {
        title: "APN",
        value: landInformation?.apn,
      },
      {
        title: "Zoning",
        value: lot?.zoningCode ? lot?.zoningCode :"-",
      },
      {
        title: "Census Tract",
        value: landInformation?.censusTract,
      },
      {
        title: "Block",
        value:  landInformation?.block,
      },
      {
        title: "Lot Number",
        value: landInformation?.lotNumber ? landInformation?.lotNumber : "",
      },
      {
        title: "Neighborhood Name",
        value: legal?.subdivisionName ? legal?.subdivisionName :"-",
      },
      {
        title: "Legal Description",
        value: legal?.legalDescription,
      },
    ],
  };

  useEffect(() => {
    const selectedOwner = owner?.find((owner: any) => owner.createdBy);
    const updateName = selectedOwner 
      ? `${selectedOwner.nameFirst || ""} ${selectedOwner.nameLast || ""}`
      : propertyDetails?.ownerInfo?.ownerNames?.[0]?.fullName ?? "-";
    setOwnerFullName(updateName);
  }, [propertyDetails, owner]);

  const BelowMap = () => {
    return (
      <div className={` ${styles.propertyDetailsTab}`}>
          <div
            className={`dflex justifySpaceBetween ${styles.propertyDetails}`}
          >
            <div className={`${styles.propertyBox}`}>
              <h3>Owner Name</h3>
              <p className={`${styles.propertyBox__edit}`}>
                {ownerFullName}
               
              </p>
              <h3>Occupancy</h3>
              <p>
                {ownerOccupied
                  ? "Owner Occupied"
                  : absenteeOwner
                  ? "Abstentee Owner"
                  : "Vacant"}
              </p>
            </div>
            <div className={`${styles.propertyBox}`}>
              <h3>
                Mortgages <span>{currentMortgages?.length||0}</span>
              </h3>
              <p>
                {currentMortgages?.length !== 0
                  ? formatCurrency(
                      currentMortgages?.[0]?.currentEstimatedBalance
                    ) +
                    " | " +
                    formatCurrency(
                      currentMortgages?.[0]?.estimatedPaymentAmount
                    ) +
                    "/mo"
                  : "-"}
              </p>

              <h3>Taxes</h3>
              <p>{formatCurrency(tax?.taxAmount / 12)}/mo</p>
            </div>
            <div className={`${styles.propertyBox}`}>
              <h3>
                Equity <small>(est.)</small>
              </h3>
              {propertyDetails?.valuation && valuation ? (
                <>
                  <p>
                    {formatCurrency(valuation?.equityCurrentEstimatedBalance)} |{" "}
                    {valuation?.equityPercent}% |{" "}
                    {valuation?.equityPercent < 35
                      ? "Low"
                      : valuation?.equityPercent >= 35 &&
                        valuation?.equityPercent <= 65
                      ? "Average"
                      : "High"}
                  </p>
                  <div className={`${styles.progress}`}>
                    <BorderLinearProgress
                      variant="determinate"
                      value={valuation?.equityPercent}
                    />
                  </div>
                </>
              ) : (
                "-"
              )}
              <h3>Est. Value</h3>
              <p>{formatCurrency(valuation?.estimatedValue)}</p>
            </div>
            <div className={`${styles.propertyBox}`}>
              <h3>Last Sale</h3>
              <p>{formatMonthYear(intel?.lastSoldDate)}</p>
              <h3>MLS</h3>
              <p>
                {mlsActive || mlsPending
                  ? formatCurrency(mlsHistory[0]?.price)
                  : "Inactive"}
              </p>
            </div>
            <div className={`${styles.propertyBox}`}>
              <h3>
                Rent <small>(est.)</small>
              </h3>
              <p>
                {demographics?.suggestedRent
                  ? `${formatCurrency(demographics?.suggestedRent)}/mo`
                  : "-"}
              </p>
            </div>
          </div>
        </div>

    );
  };

  return (
    <div className={` ${styles.propertyDetails}`}>
      <BelowMap/>
        {Object.entries(sections).map(([key, value]) => (
          <ShadowBox title={key} key={key}>
            <div className={`dflex ${styles.row}`}>
              {value.map(({ title, value }) => (
                <SectionItem title={title} value={value? value.toString() : ''} key={title} />
              ))}
            </div>
          </ShadowBox>
        ))}
    </div>
  );
};
export default PropertyDetailTab;
