import React, { useEffect, useState } from "react";
import Input from "../../atoms/Input";
import Button from "../../atoms/Button";
import { useForm } from "react-hook-form";
import DropDown from "../../atoms/Dropdown";
import {
  COUPON_DURATION_TYPE_OPTIONS,
  DISCOUNT_TYPE_OPTIONS,
} from "./constant";
import CustomSwitch from "../../atoms/CustomSwitch";
import CurrencyDollar from "../../assets/icons/currencyDoller";
import Percent from "../../assets/icons/percent";
import DatePickerInTable from "../../atoms/DatePickerInTable";
import { createCouponSchema } from "./validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { ToastType } from "../../molecules/toaster/enum";
import { addToast } from "../../molecules/toaster/toastSlice";
import { useDispatch, useSelector } from "react-redux";
import { CustomError } from "../../models/customError.interface";
import { createStripeCoupon } from "../../pages/couponsTab/service";
import { getCouponList, getCustomerDetail, getCustomerList, getCustomerListLoading, getProductList } from "../../pages/couponsTab/selector";
import MultiSelectDropDown from "../../atoms/MultiSelectDropDown";
import SearchReflation from "../../assets/icons/searchReflation";
import { getOrganizationList } from "../../pages/organization/selector";
import { fetchCustomerList, fetchProductList } from "../../pages/couponsTab/sagaAction";
import MultiSelectScrollDropDown from "../../atoms/MultiSelectScrollDropDown";
import { setCouponList } from "../../pages/couponsTab/reducer";

const CreateCoupon = ({ handleClose }: any) => {
  const dispatch = useDispatch();
  const productList = useSelector((state) => getProductList(state));
  const customerDetail = useSelector((state) => getCustomerDetail(state));
  const customerList = useSelector((state) => getCustomerList(state));
  const customerListLoading = useSelector((state) => getCustomerListLoading(state));
  const {
    formState: { errors },
    register,
    handleSubmit,
    setValue,
    watch,
    control,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(createCouponSchema),
  });

  const organizations = useSelector((state) => getOrganizationList(state));
  const couponList = useSelector((state) => getCouponList(state));
  const [productListOptions, setProductListOptions] = useState([]);
  const [customerListOptions, setCustomerListOptions] = useState([]);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const handleShowToast = (
    message: string,
    type: ToastType = ToastType.success
  ) => {
    dispatch(
      addToast({
        id: new Date().getTime(),
        message: message,
        type: type,
      })
    );
  };

  const onSubmit = async (data: any) => {
    setButtonLoading(true);
    try {
      const filteredData = Object.fromEntries(
        Object.entries(data).filter(
          ([_, value]) =>
            value !== null && value !== "" && value !== 0 && value !== false
        )
      );
    const res=await createStripeCoupon(filteredData)
     dispatch(setCouponList([res?.data?.data,...couponList]));
      setButtonLoading(false);
      handleClose();
      handleShowToast("Coupon created successfully!", ToastType.success);
    } catch (error) {
      setButtonLoading(false);
      handleShowToast(
        (error as CustomError)?.response?.data?.message ||
          "Something went wrong!",
        ToastType.error
      );
    }
  };

  useEffect(() => {
    const options = productList?.items?.map((list: any) => ({
      value: list.id,
      label: list.name,
    }));
    setProductListOptions(options);
  }, [productList]);

  useEffect(() => {
    if(Object.keys(customerList).length >0){
    const options = customerList?.map((list: any) => ({
      value: list.id,
      label: list.email,
    }));
    setCustomerListOptions(options);}
  }, [customerList]);

  useEffect(() => {
    if (!watch("isProduct")) {
      setValue("products", []); 
    }
  }, [watch("isProduct"), setValue]);

  useEffect(() => {
    if (!watch("isCustomer")) {
      setValue("customers", []); 
    }
  }, [watch("isCustomer"), setValue]);

  useEffect(() => {
    if (!watch("dateRange")) {
      setValue("redeem_by", ""); 
    }
  }, [watch("dateRange"), setValue]);

  useEffect(() => {
    if (!watch("noOfTimesRedeem")) {
      setValue("maxRedemptions", 0); 
    }
  }, [watch("noOfTimesRedeem"), setValue]);

  return (
    <div>
      <div className="mainCol">
        <div className="mainCol__half">
          <Input
            type="text"
            label="Name*"
            register={register}
            name="name"
            placeholder="Enter Coupon name"
            errors={errors}
          />
        </div>
        <div className="mainCol__half">
          <Input
            type="uppercase"
            label="Coupon Code*"
            register={register}
            name="code"
            placeholder="Enter Coupon Code"
            errors={errors}
          />
        </div>

        <div className="mainCol__half mb-15">
          <DropDown
            options={COUPON_DURATION_TYPE_OPTIONS}
            label="Coupon Duration*"
            control={control}
            name="duration"
            errors={errors}
            placeholder="Select Coupon Duration"
          ></DropDown>
        </div>
        <div className="mainCol__half">
          {watch("duration") === "repeating" && (
            <Input
              type="number"
              label="Number of months*"
              register={register}
              name="durationInMonths"
              placeholder="Enter Number of months"
              errors={errors}
            />
          )}
        </div>
        <div className="mainCol__half">
          <DropDown
            options={DISCOUNT_TYPE_OPTIONS}
            label="Discount Type*"
            control={control}
            name="discountType"
            errors={errors}
            placeholder="Select Discount Type"
          ></DropDown>
        </div>
        <div className="mainCol__half ">
          {watch("discountType") === "amount" && (
            <Input
              type="currency"
              label="Discount amount*"
              register={register}
              control={control}
              name="amount"
              placeholder="Enter Discount amount"
              errors={errors}
              prefix={<CurrencyDollar />}
            />
          )}

          {watch("discountType") === "percentage" && (
            <Input
              type="number"
              label="Percentage off*"
              register={register}
              name="percentOff"
              placeholder="Enter Percentage"
              prefix={<Percent />}
              errors={errors}
            />
          )}
        </div>
      </div>
      <div className="flex alignCenter gap-10 my-15">
        <CustomSwitch
          control={control}
          name="isProduct"
          defaultChecked={false}
        />
        <label className="label mb-0"> Apply to specific products</label>
      </div>
      {watch("isProduct") && (
        <MultiSelectDropDown
          options={productListOptions}
          label=""
          errors={errors}
          control={control}
          name="products"
          emptySearchMetaData={{
            icon: <SearchReflation />,
            title: "No results found",
          }}
        />
      )}
      <div className="flex alignCenter gap-10 my-15">
        <CustomSwitch
          control={control}
          name="isCustomer"
          defaultChecked={false}
        />
        <label className="label mb-0"> Apply to specific customers</label>
      </div>
      {watch("isCustomer") && (
        <MultiSelectScrollDropDown
          options={customerListOptions}
          label=""
          control={control}
          name="customers"
          errors={errors}
          emptySearchMetaData={{
            icon: <SearchReflation />,
            title: "No results found",
          }}
          onLoadMore={() => {
            if (customerDetail.hasMore) {
                dispatch(fetchCustomerList({limit:30,lastId:customerDetail.lastId}));
            }
          }}
          hasMore={customerDetail.hasMore} 
          isLoading={customerListLoading}
        />
      )}
      <h4 className="my-15">Redemption limits</h4>
      <div className="flex alignCenter gap-10 my-15">
        <CustomSwitch
          control={control}
          name="dateRange"
          defaultChecked={false}
        />
        <label className="label mb-0">
          Limit the date range when customers can redeem this coupon
        </label>
      </div>
      {watch("dateRange") && (
        <DatePickerInTable
          name={`redeem_by`}
          errors={errors}
          control={control}
          calenderCheckIcon={true}
          onDateChange={(value: any) => {
            if (value) {
              setValue("redeem_by", value?.split("T")[0],{ shouldValidate: true });
            } else {
              setValue("redeem_by", "",{ shouldValidate: true });
            }
          }}
          placeHolder="Pick a date"
        />
      )}
      <div className="flex alignCenter gap-10 my-15">
        <CustomSwitch
          control={control}
          name="noOfTimesRedeem"
          defaultChecked={false}
        />
        <label className="label mb-0">
          Limit the total no of times this coupon can be redeemed
        </label>
      </div>
      {watch("noOfTimesRedeem") && (
        <Input
          type="number"
          register={register}
          name="maxRedemptions"
          errors={errors}
        />
      )}
      <div className="actions">
        <div className="mainCol">
          <div className="mainCol__half">
            <Button
              label="Cancel"
              action={handleClose}
              className="outline full"
            ></Button>
          </div>
          <div className="mainCol__half">
            <Button
              label="Create Coupon"
              action={handleSubmit(onSubmit)}
              clicked={buttonLoading}
              disabled={buttonLoading}
              className="primary full animated"
            ></Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateCoupon;
