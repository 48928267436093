import { takeLatest, put, all } from "redux-saga/effects";
import * as slice from "./reducer"
import * as sagaActions from "./sagaAction"
import { getAPI, postAPI } from "../../apis/apis";

export interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
}

function* fetchLogAttemptsList({payload}:any) {
  const {page,size,id}=payload
  console.log(page)
  if (page === 1) {
    yield put(slice.setLogAttemptListLoading(true));
  }
  try {
    const response: ResponseGenerator = yield getAPI({
      url:
        process.env.REACT_APP_BASE_URL +
        `/admin/activity-log/call-attempt/${id}?page=${page}&size=${size}`,
      headers: {},
      data: {},
    });
    yield all([
      put( page > 1 ? slice.appendLogAttemptList(response?.data?.data?.data) : slice.setLogAttemptList(response?.data?.data?.data)),
      put(slice.setLogAttemptListCount(response?.data?.data?.totalCount)),
      put(slice.setLogAttemptListLoading(false)),

    ]);
  } catch (error: any) {
    yield all([
      put(slice.setLogAttemptListLoading(false)),
    ]);
  }
}

function* logAttemptsSaga() {
    yield takeLatest(sagaActions.fetchLogAttemptsList, fetchLogAttemptsList);
  }
  
export default logAttemptsSaga;