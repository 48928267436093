const Follow = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
      >
        <path
          d="M35 19.1668V14.6668C35 11.8666 35 10.4664 34.455 9.39688C33.9757 8.45607 33.2108 7.69116 32.27 7.2118C31.2004 6.66683 29.8003 6.66683 27 6.66683H13C10.1997 6.66683 8.79961 6.66683 7.73005 7.2118C6.78924 7.69116 6.02433 8.45607 5.54497 9.39688C5 10.4664 5 11.8666 5 14.6668V28.6668C5 31.4671 5 32.8672 5.54497 33.9368C6.02433 34.8776 6.78924 35.6425 7.73005 36.1219C8.79961 36.6668 10.1997 36.6668 13 36.6668H20.8333M35 16.6668H5M26.6667 3.3335V10.0002M13.3333 3.3335V10.0002M30 35.0002V25.0002M25 30.0002H35"
          stroke="url(#paint0_linear_21497_46222)"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear_21497_46222"
            x1="5"
            y1="3.3335"
            x2="38.1492"
            y2="33.1677"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#72EDF2" />
            <stop offset="1" stopColor="#5151E5" />
          </linearGradient>
        </defs>
      </svg>
    </>
  );
};

export default Follow;
