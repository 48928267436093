import styles from "./Chat.module.scss";
import UserIconRound from "../../assets/icons/userIconRound";
const Message = ({
  alignRight = false,
  dateTime,
  body,
  createdByName,
  id,
  handleClick,
  setSelectedCommentId,
  message,title
}: {
  dateTime: string;
  body: string;
  createdByName: string;
  id: string;
  alignRight: boolean;
  handleClick:Function
  setSelectedCommentId:Function
  message:any
  title:string
}) => {
  return (
    <div
      className={` ${styles.message} ${
        alignRight ? styles.messageReceiver : ""
      } flex alignStart`}
      key={id}
    >
      <div className={` ${styles.message__textImg} `}>
        <figure className="flex alignCenter justifyCenter">
          <UserIconRound />
        </figure>
      </div>
      <div className={` ${styles.message__textInfo} `}>
        <div
          className={` ${styles.message__textInfoHeader} flex justifySpaceBetween`}
        >
          <p className="flex alignCenter"><span>{createdByName}</span>
            </p>
          <p>{dateTime}</p>
        </div>
        <div
          className={` ${styles.message__textInfoText} `}
          dangerouslySetInnerHTML={{ __html: body }}
        ></div>
      </div>
    </div>
  );
};
export default Message;
