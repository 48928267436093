import { useSelector } from "react-redux";
import AnalyticCards from "../../../../organisms/analyticCards/AnalyticCards";
import { formatNumber } from "../../../../utils/utils";
import styles from "./LinkedTab.module.scss";
import { getPropertyDetailsData } from "../../selector";
const LinkedTab = () => {
  const propertyDetails = useSelector((state) => getPropertyDetailsData(state));
  const { propertyOwnerProfile } = propertyDetails;

  return (
    <div className={` ${styles.linked}`}>
        <div className={`dflex ${styles.cards}`}>
          <div className={` ${styles.card}`}>
            <AnalyticCards
              title="Average Purchase Price"
              value={
                propertyOwnerProfile?.averagePurchasePrice
                  ? `$ ${formatNumber(
                      parseInt(propertyOwnerProfile?.averagePurchasePrice)
                    )}`
                  : "-"
              }
            />
          </div>
          <div className={` ${styles.card}`}>
            <AnalyticCards
              title="Average Year Built"
              value={
                propertyOwnerProfile?.averageYearBuilt
                  ? `${propertyOwnerProfile?.averageYearBuilt}`
                  : "-"
              }
            />
          </div>
          <div className={` ${styles.card}`}>
            <AnalyticCards
              title="Mortgages Average Balance"
              value={
                propertyOwnerProfile?.mortgagesAverageBalance
                  ? `$ ${formatNumber(
                      parseInt(propertyOwnerProfile?.mortgagesAverageBalance)
                    )}`
                  : "-"
              }
            />
          </div>
          <div className={` ${styles.card}`}>
            <AnalyticCards
              title="Mortgages Total Balance"
              value={
                propertyOwnerProfile?.mortgagesTotalBalance
                  ? `$ ${formatNumber(
                      parseInt(propertyOwnerProfile?.mortgagesTotalBalance)
                    )}`
                  : "-"
              }
            />
          </div>
          <div className={` ${styles.card}`}>
            <AnalyticCards
              title="Mortgages Count"
              value={
                propertyOwnerProfile?.mortgagesCount
                  ? `${propertyOwnerProfile?.mortgagesCount}`
                  : "-"
              }
            />
          </div>
          <div className={` ${styles.card}`}>
            <AnalyticCards
              title="Properties Count"
              value={
                propertyOwnerProfile?.propertiesCount
                  ? `${propertyOwnerProfile?.propertiesCount}`
                  : "-"
              }
            />
          </div>
          <div className={` ${styles.card}`}>
            <AnalyticCards
              title="Properties Total Equity"
              value={
                propertyOwnerProfile?.propertiesTotalEquity
                  ? `$ ${formatNumber(
                      parseInt(propertyOwnerProfile?.propertiesTotalEquity)
                    )}`
                  : "-"
              }
            />
          </div>
          <div className={` ${styles.card}`}>
            <AnalyticCards
              title="Properties Total Estimated Value"
              value={
                propertyOwnerProfile?.propertiesTotalEstimatedValue
                  ? `$ ${formatNumber(
                      parseInt(
                        propertyOwnerProfile?.propertiesTotalEstimatedValue
                      )
                    )}`
                  : "-"
              }
            />
          </div>
          <div className={` ${styles.card}`}>
            <AnalyticCards
              title="Total Purchase Price"
              value={
                propertyOwnerProfile?.totalPurchasePrice
                  ? `$ ${formatNumber(
                      parseInt(propertyOwnerProfile?.totalPurchasePrice)
                    )}`
                  : "-"
              }
            />
          </div>
        </div>
      </div>
  );
};
export default LinkedTab;
