import { takeLatest, put, all } from "redux-saga/effects";
import * as slice from "./reducer";
import * as sagaActions from "./sagaAction";
import { getAPI, postAPI, putAPI } from "../../apis/apis";
import { addToast } from "../../molecules/toaster/toastSlice";
import { ToastType } from "../../molecules/toaster/enum";

export interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
}

function* fetchLeadList({ payload }: any) {
  const { index, size,orgId,search ,leadStatus,campaignIds} = payload;
  if (index === 1) {
    yield put(slice.setLeadListLoading(true));
  }
  try {
    const response: ResponseGenerator = yield getAPI({
      url: `${process.env.REACT_APP_BASE_URL}/admin/campaign/lead`,
      data: {
        ...payload,
      },
      headers: {},

    });
    yield all([
      put(
        (payload?.index ?? 0) > 1
          ? slice.appendLeadList(response?.data?.result)
          : slice.setLeadList(response?.data?.result)
      ),
      put(slice.setLeadListCount(response?.data?.count)),
      put(slice.setLeadListLoading(false)),
    ]);
  } catch (error: any) {
    yield all([
      put(slice.setLeadListLoading(false)),
      put(
        addToast({
          id: new Date().getTime(),
          message: error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message ?? "Something went wrong!",
          type: ToastType.error,
        })
      ),
    ]);
  }
}

function* propertyUpdateDetails({ payload }: any) {
  const { id, followUpAt, status,organisationId } = payload;
  yield put(
    slice.handleLoaderForRow({ data: { id, key: "isLoading", value: true } })
  );
  try {
    yield putAPI({
      url: `${process.env.REACT_APP_BASE_URL}/admin/campaign/property-update/${id}`,
      data: { followUpAt: followUpAt, status: status ,organisationId:organisationId},
      headers: {},
    });
    if (status) {
      yield put(
        slice.handleLoaderForRow({ data: { id, key: "status", value: status } })
      );
    }
    yield put(
      slice.handleLoaderForRow({
        data: { id, key: "updatedAt", value: new Date() },
      })
    );
  } catch (error) {
    console.log("error =", error);
    yield put(
      addToast({
        id: new Date().getTime(),
        message: "Lead not updated!",
        type: ToastType.error,
      })
    );
  } finally {
    yield put(
      slice.handleLoaderForRow({ data: { id, key: "isLoading", value: false } })
    );
  }
}

function* fetchPropertyDetails({ payload }: any) {
  const{propertyId, orgId}= payload;
  yield put(slice.setPropertyDetailLoading(true))
  try {
    const response: ResponseGenerator = yield postAPI({
      url: process.env.REACT_APP_BASE_URL + `/admin/campaign/property-details/${propertyId}/${orgId}`,
      headers: {},
      data: {},
    });
    console.log(response)
    yield all([
      put(slice.setPropertyDetails(response?.data?.results[0])),
      put(slice.setPropertyDetailLoading(false)),
    ]);
  } catch (error) {
    yield put(slice.setPropertyDetailLoading(false))
  }
}

function* fetchWholesaleCalculator({ payload }: any) {
  const {orgId,propertyId}= payload
  try {
    yield put(slice.setwholesaleCalculatorLoading(true));
    const response: ResponseGenerator = yield getAPI({
      url:
        process.env.REACT_APP_BASE_URL +
        `/admin/campaign/property-calculator/wholesale/${orgId}/${propertyId}`,
      headers: {},
    });
    yield all([
      put(slice.setwholesaleCalculatorlist(response.data)),
      put(slice.setwholesaleCalculatorLoading(false)),
    ]);
  } catch (error: any) {
    yield all([
      put(slice.setwholesaleCalculatorLoading(false)),
      put(
        addToast({
          id: new Date().getTime(),
          message: error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message ?? "Something went wrong!",
          type: ToastType.error,
        })
      ),
    ]);
  }
}

function* fetchFinanceCalculatorAction({payload}:any) {
  const {orgId}= payload
  try {
    const response: ResponseGenerator = yield getAPI({
      url:
        process.env.REACT_APP_BASE_URL +
        `/admin/campaign/property-calculator/finance/${orgId}`,
      headers: {},
    });
    yield all([
      put(slice.setFinanceCalculatorlist(response.data)),
    ]);
  } catch (error: any) {
    yield all([
      put(
        addToast({
          id: new Date().getTime(),
          message: error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message ?? "Something went wrong!",
          type: ToastType.error,
        })
      ),
    ]);
  }
}

function* fetchFinanceIdCalculatorAction({ payload }: any) {
  const {orgId,id}= payload
  try {
    yield put(slice.setFinanceCalculatorLoading(true));
    const response: ResponseGenerator = yield getAPI({
      url:
        process.env.REACT_APP_BASE_URL +
        `/admin/campaign/property-calculator/finance/${orgId}/${id}`,
      headers: {},
    });
    yield all([
      put(slice.setFinanceCalculatorDetail(response.data)),
      put(slice.setFinanceCalculatorLoading(false)),
    ]);
  } catch (error: any) {
    yield all([
      put(slice.setFinanceCalculatorLoading(false)),
      put(
        addToast({
          id: new Date().getTime(),
          message: error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message ?? "Something went wrong!",
          type: ToastType.error,
        })
      ),
    ]);
  }
}

function* fetchAmortizationCalculator({ payload }: any) {
  const {orgId,propertyId}= payload
  try {
    yield put(slice.setamortizationCalculatorLoading(true));
    const response: ResponseGenerator = yield getAPI({
      url:
        process.env.REACT_APP_BASE_URL +
        `/admin/campaign/property-calculator/amortization/${orgId}/${propertyId}`,
      headers: {},
    });
    yield all([
      put(slice.setamortizationCalculatorlist(response.data)),
      put(slice.setamortizationCalculatorLoading(false)),
    ]);
  } catch (error: any) {
    yield all([
      put(slice.setamortizationCalculatorLoading(false)),
      put(
        addToast({
          id: new Date().getTime(),
          message: error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message ?? "Something went wrong!",
          type: ToastType.error,
        })
      ),
    ]);
  }
}

function* leadListSaga() {
  yield takeLatest(sagaActions.fetchLeadList, fetchLeadList);
  yield takeLatest(sagaActions.propertyUpdateDetailsAction,propertyUpdateDetails)
  yield takeLatest(sagaActions.fetchPropertyDetail ,fetchPropertyDetails)
  yield takeLatest(
    sagaActions.fetchWholesaleCalculatorAction,
    fetchWholesaleCalculator
  );
  yield takeLatest(
    sagaActions.fetchAmortizationCalculatorAction,
    fetchAmortizationCalculator
  );
  yield takeLatest(sagaActions.fetchFinanceCalculatorAction,fetchFinanceCalculatorAction)
  yield takeLatest(sagaActions.fetchFinanceCalculatorIdAction,fetchFinanceIdCalculatorAction)
}

export default leadListSaga;
