export const ROUTES = {
    HOME: '/',
    ORGANIZATION: '/organisation',
    NOT_MATCH: '*',
    USERS: '/users',
    ROLEANDPERMISSION: '/roles-permission',
    CAMPAIGNS: '/campaigns',
    LEADS: '/leads',
    LIST: '/list',
    BILLINGHISTORY: '/billings-history',
    BOTCALLING:'/bot-calling',
    COUPON:'/coupon'
  };