const Street = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="11"
        height="18"
        viewBox="0 0 11 18"
        fill="none"
      >
        <rect
          y="0.428711"
          width="10.2857"
          height="17.1429"
          fill="url(#pattern0_21499_47696)"
        />
        <defs>
          <pattern
            id="pattern0_21499_47696"
            patternContentUnits="objectBoundingBox"
            width="1"
            height="1"
          >
            <use
              xlinkHref="#image0_21499_47696"
              transform="scale(0.0416667 0.025)"
            />
          </pattern>
          <image
            id="image0_21499_47696"
            width="24"
            height="40"
            xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAoCAYAAADkDTpVAAADl0lEQVR4Ad2XA5A8RxTGY9u2MzM7OIV/27Zt27Zt27bPxUv5bNuq6rxveu82i6mdiZOr+qqv+Ws8zD7EGPtL9T8BGP1pmvYwqSPpMin/hwCNobTXO6PfaK5XAE1+hXSjb3uF3dkgsaKrAqu+Legl6v07KIDdJL1qGUCTniIFr5kgs6pbWNiz1k+UAQnDeKuAqRP6KFjEqyb2VQCZaRpAgx8hpcYeE00B4o+LAGRgnlnAl22aqJhsWh2aqYB8axZQZ1AnxRJgcGcFgPpmAVrnltZO0LWVCoBqFvCEr49WkH7W3BtknReZr69WgHlWrGjtklGyKcCy0bqprrFqpi/5+GjxZ5fZDBeGf5yjfhoXTeNftgp4grTV30djS4fKLHa/yMouCqycVHpeYDG7RbaK2tFP4w6SnrPiBz/5+2n5QykUXBomsqARIrs76CsWMvIrFj7hS5R6He1XqH8MWRDFqHKa180Y4Bzc4kK3S6ziCr+G5CUiS5jrrqSFIqu6KbDKa9zZvg/QSnFV3gAqnAZ3XH6Z33XxCcEjIP8g78dGUI7rrftCF2+A6SvGyhxw0fGg6eucIakr9NPxcZd4eXiBDYCd3gD3A7dI+oTScw4ATpMwzwEoPevoK8NGCBZ9VAQgzhBAnS/QPVaVXhc44IyzWebt4afI2oK6MwBvgf+b1lcB+dQI0HZEd8QgTHAHoC11JR7VHVBp39TsITIAg40AWw/MhWPxCSmHPYQKD8kn6YhY+9AXVtgAOGEEiMc91ljGhgnmQsXWqTJOwY3hrAhADukRJwByQIsGjgj6yy6RDeqsmALMoWsJ2iDV1js155HVFTB6wXDZMWmobAkwqZ9j7PIxMgCTXQGXb6yTuLVcEuD6pgEzB8sswE9j2Rf49d7ZKAFw3RVQmm/33EPz9IeK6tveHGB8HwXjM/fbDaTwioB6mSsgdvV4mQVvlVjbJvodjujWyi2r4RGNAMuRx3ELyCNUj3EFvEuaT7pJmkiSPQEa11WNAG3xjqTTpAmkN73kA48AJBeWe9EzwGrCkbu6ALAwtSM0O7UP6aIDGlgFfNSyoTMgjhYGIGKfEwDWhva6fxgQsVfSAQ+2SH8N4P5mDri06i8CXFzJAUcW2Jzae7dV0e5vFfB6g5+dAfaMxbZNcwbA2mAUlr+L6v6oOS20eaqMhXIRa/4MwGNk81GA1Og7f62a2udR3Kn4TTs+G9P5rxxjwF+qXwHY43o8sjMMgAAAAABJRU5ErkJggg=="
          />
        </defs>
      </svg>
    </>
  );
};

export default Street;
