import { Switch, Tooltip } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { useEffect, useRef,useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../atoms/Loader";
import EmptyPlaceholder from "../../atoms/EmptyPlaceholder";
import PillDropdown from "../../atoms/PillDropdown";
import DatePickerInTable from "../../atoms/DatePickerInTable";
import { copyToClipboard, dateFn, formatPhoneNumber } from "../../utils/utils";
import TickIcon from "../../assets/icons/tickIcon";
import CrossIcon from "../../assets/icons/crossIcon";
import Pill from "../../atoms/Pill";
import { getPillStatus } from "../../constant/status.constant";
import { getPillStatusIcon } from "../../atoms/PillStatusIcon";
import Copy from "../../assets/icons/copy";
import DropDown from "../../atoms/Dropdown";

function emptyFunction() {}

const CustomTable = ({
  rows,
  columns,
  tableStriped,
  search = "",
  emptySearchMetaData,
  emptyTableMetaData,
  hasMore,
  editname,
  options,
  handleClickedPill,
  control,
  errors,
  handleDateFn,
  handleNext,
  handleNextPage,
  onChange,
  buttonLoader,
  callAttemptsStatusOptions,
  lastColSticky,permissionAccess= false
}: any) => {
  const customTableContainerRef =
    useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;
  const customTableLoaderRef =
    useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;
  const [tableData, setTableData] = useState(rows);
  const hasMoreRef = useRef<boolean>(false);
  hasMoreRef.current = hasMore;
  const intersectionObserverRef = useRef<IntersectionObserver | null>(null);
  useEffect(() => {
    setTableData(rows);
  }, [rows]);

  useEffect(() => {
    if (!rows?.length) {
      return;
    }
    let timeoutId: string | number | NodeJS.Timeout;
    intersectionObserverRef.current = new IntersectionObserver(
      (entries, opts) => {
        entries.forEach((entry) => {
          if (!entry.isIntersecting || !hasMoreRef.current) {
            return;
          }
          timeoutId = setTimeout(() => {
            console.log("check timeout");
            handleNextPage();
          }, 1000);
        });
      },
      {
        root: customTableContainerRef.current,
        threshold: 0.9,
      }
    );

    let tableLoader = customTableLoaderRef.current;

    if (tableLoader) {
      intersectionObserverRef.current.observe(tableLoader);
    }

    return () => {
      if (tableLoader) {
        intersectionObserverRef.current?.unobserve(tableLoader);
      }
      intersectionObserverRef.current?.disconnect();
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [rows]);

  return (
    <div className={`custom-table ${lastColSticky ? `lastColSticky` : ``} ${tableStriped ? `table-striped` : ``}`}>
      <div
        className="custom-table-wrapper"
        id="scrollableDiv"
        ref={customTableContainerRef}
      >
        <InfiniteScroll
          dataLength={tableData?.length ?? 0}
          next={() => handleNextPage && handleNextPage()}
          hasMore={hasMore}
          scrollableTarget="scrollableDiv"
          loader={
            <div className="table-loader" ref={customTableLoaderRef}>
              <Loader></Loader>
            </div>
          }
        >
        <Table stickyHeader aria-label="sticky table">
          {rows?.length > 0 && (
            <TableHead>
              <TableRow>
                {columns.map((column: any) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {rows?.length ? (
              rows.map((row: any, rowIndex: number) => {
                if (row?.status === "Cancelled") return null;
                else {
                  return (
                    <>
                      {row?.isLoading ? (
                        <TableRow>
                          <TableCell colSpan={columns?.length}>
                            <div className="table-loader">
                              <Loader ></Loader>
                            </div>
                          </TableCell>
                        </TableRow>
                      ) : (
                        <TableRow
                          hover
                          role={row?.handleOnClick ? "link" : "checkbox"}
                          tabIndex={-1} 
                          key={row?.code || row?.id}
                          onClick={row?.handleOnClick ?? emptyFunction}
                      
                        >
                          {columns.map((column: any) => {
                            const value: any = row[column?.id];
                            // console.log(row)
                            switch (column.type) {
                              case "number":
                                return (
                                  <TableCell
                                    key={column?.id}
                                    align={column?.align}
                                  >
                                    {column?.format && typeof value === "number"
                                      ? column.format(value)
                                      : value}
                                  </TableCell>
                                );
                              case "status":
                                return (
                                  <TableCell
                                    key={column?.id}
                                    align={column?.align}
                                  >
                                    <Tooltip
                                      title={value?.toolTipText}
                                      placement="top"
                                    >
                                      <div>
                                        <Pill
                                          label={
                                            value?.label ? value?.label : value
                                          }
                                          className={getPillStatus(
                                            value?.label ? value?.label : value
                                          )}
                                          infix={getPillStatusIcon(
                                            value?.label ? value?.label : value
                                          )}
                                        />
                                      </div>
                                    </Tooltip>
                                  </TableCell>
                                );
                              case "name":
                                return (
                                  <TableCell
                                    key={column?.id}
                                    align={column?.align}
                                  >
                                    <Tooltip
                                      title={
                                        permissionAccess
                                          ? "You don't have permission to access this."
                                          : ""
                                      }
                                    >
                                      <div>
                                        <span
                                          className={`campaign-name ${
                                            permissionAccess ? "disable" : ""
                                          }`}
                                          onClick={() => {
                                            handleNext(row?.id);
                                          }}
                                          role="link"
                                        >
                                          {value}
                                        </span>
                                      </div>
                                    </Tooltip>
                                  </TableCell>
                                );
                              case "icon":
                                return (
                                  <TableCell
                                    key={column?.id}
                                    align={column.align}
                                  >
                                    <span className="inlineFlex" role="link">
                                      {value}{" "}
                                    </span>
                                  </TableCell>
                                );
                              case "Switch":
                                return (
                                  <TableCell
                                    key={column?.id}
                                    align={column.align}
                                  >
                                    <Switch
                                      data-testid="Switch"
                                      defaultChecked={row.vaAccess}
                                      value={value}
                                      onChange={(event, val) => {
                                        return onChange(val, row.id);
                                      }}
                                      disabled={buttonLoader || row?.access}
                                    />
                                  </TableCell>
                                );
                              case "titleDescription":
                                return (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                  >
                                    <div
                                      className="titleDesc flex tableCopy alignCenter "
                                      role="link"
                                    >
                                      <p>
                                        {value?.title}{" "}
                                        {value?.copyLeadName
                                          ? value?.copyLeadName
                                          : ""}
                                      </p>
                                    </div>
                                    <div
                                      className="titleDesc flex tableCopy alignCenter"
                                      role="link"
                                    >
                                      {value?.status === "uploading" ? (
                                        <div>
                                          Processing
                                          <span className="yellowDot"></span>
                                        </div>
                                      ) : value?.status === "failed" ? (
                                        <div>
                                          {value?.description}
                                          <span className="redDot"></span>
                                        </div>
                                      ) : value?.status === "success" ? (
                                        <div>
                                          {value?.description}

                                          {(value?.description ===
                                            "Purchased" ||
                                            value?.description ===
                                              "Uploaded") && (
                                            <span className="greenDot"></span>
                                          )}
                                        </div>
                                      ) : (
                                        <>
                                          <span
                                            className="titleDesc__blue"
                                            role="link"
                                            onClick={
                                              row?.handleOnClicked ??
                                              emptyFunction
                                            }
                                          >
                                            {value?.description}{" "}
                                            {value?.copyAddress
                                              ? value?.copyAddress
                                              : ""}
                                          </span>
                                        </>
                                      )}
                                    </div>
                                  </TableCell>
                                );
                              case "statusPill":
                                return (
                                  <TableCell
                                    key={column?.id}
                                    align={column?.align}
                                  >
                                    {row?.rootUser ? (
                                      row?.roleName
                                    ) : (
                                      <PillDropdown
                                        options={options}
                                        label={
                                          value?.label ? value?.label : value
                                        }
                                        disabled
                                        onChange={(value: any) =>
                                          handleClickedPill({
                                            id: row?.id,
                                            value: value,
                                            flag: row?.flag ? row?.flag : false,
                                          })
                                        }
                                      />
                                    )}
                                  </TableCell>
                                );
                              case "dateCalenderCell":
                                return (
                                  <TableCell
                                    key={column?.id}
                                    align={column?.align}
                                    data-testid="dateEditLeadsTestId"
                                  >
                                    <div className="tableDatePicker">
                                      <DatePickerInTable
                                        name={`follow_up_at_${row?.id}`}
                                        errors={errors}
                                        control={control}
                                        calenderCheckIcon={true}
                                        defaultValue={
                                          row?.followUpDate !== "None"
                                            ? dateFn(row?.followUpDate)
                                            : null
                                        }
                                        onDateChange={(value: any) => {
                                          handleDateFn({
                                            id: row?.id,
                                            followUpAt: value,
                                          });
                                        }}
                                        placeHolder="Pick a date"
                                      />
                                    </div>
                                  </TableCell>
                                );
                              case "clampedText": {
                                return (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {/* {media !== MEDIA_TYPES.DESKTOP ? (
                                          <Tooltip
                                            title={
                                              value === "-" || value == null
                                                ? ""
                                                : value
                                            }
                                            enterDelay={1000}
                                            enterNextDelay={1000}
                                          >
                                            <span className="clampedText">
                                              {value ?? "-"}
                                            </span>
                                          </Tooltip>
                                        ) : ( */}
                                    <>
                                      <div className="flex tableCopyRow alignCenter justifySpaceBetween">
                                        <span className="clampedText">
                                          {value ?? "-"}
                                        </span>
                                        <span>
                                          {value === "-"
                                            ? ""
                                            : row?.copySummary
                                            ? row?.copySummary
                                            : ""}
                                        </span>
                                      </div>
                                    </>
                                    {/* // )} */}
                                  </TableCell>
                                );
                              }
                              case "permission":
                                return (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                  >
                                    <div
                                      className={`${
                                        value === false ? "red" : "green"
                                      }  tableIcon `}
                                    >
                                      {value === false ? (
                                        <CrossIcon />
                                      ) : (
                                        <TickIcon />
                                      )}
                                    </div>
                                  </TableCell>
                                );

                              case "Phone":
                                return (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                  >
                                    <div className="flex noWrap tableCopyRow alignCenter justifySpaceBetween">
                                      <span>{value ?? "-"}</span>
                                      <span>
                                        {value === "-"
                                          ? " "
                                          : value === row?.phone
                                          ? row?.copyPhone
                                            ? row?.copyPhone
                                            : ""
                                          : ""}
                                      </span>
                                    </div>
                                  </TableCell>
                                );
                              case "MultipleValues":
                                return (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                  >
                                    <div className="dflex multi-value ">
                                      <>
                                        {value.map((el: any) => {
                                          return <span>{el}</span>;
                                        })}
                                      </>
                                    </div>
                                  </TableCell>
                                );
                              case "summary":
                                return (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                  >
                                    <div
                                      className={`summaryText`}
                                      dangerouslySetInnerHTML={{
                                        __html: row.summary,
                                      }}
                                    ></div>
                                  </TableCell>
                                );
                              case "callStatus":
                                return (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {value.map((call: any, index: any) => {
                                      return (
                                        <div
                                          className={`flex alignCenter callStatus`}
                                        >
                                          <figure className="flex alignCenter">
                                            <label>Phone {index + 1}</label>
                                          </figure>
                                          <figcaption>
                                            <div
                                              className={` flex alignCenter`}
                                            >
                                              <a
                                                href={`tel:${call.phoneNumber}`}
                                              >
                                                {formatPhoneNumber(
                                                  call.phoneNumber
                                                )}
                                              </a>
                                              <Tooltip
                                                title={"Copy Phone Number"}
                                              >
                                                <span
                                                  role="link"
                                                  className={`copy-icon`}
                                                  onClick={(e: any) => {
                                                    e.stopPropagation();
                                                    copyToClipboard(
                                                      call.phoneNumber
                                                    );
                                                  }}
                                                >
                                                  <Copy />
                                                </span>
                                              </Tooltip>
                                            </div>
                                          </figcaption>
                                          <div className="statusDropdown">
                                            {call?.status === "Hidden" ? (
                                              <span className="statusPill">
                                                Unknown
                                              </span>
                                            ) : (
                                              <DropDown
                                                options={
                                                  callAttemptsStatusOptions
                                                }
                                                clearOnBlur={true}
                                                label=""
                                                control={control}
                                                name={`status_${call.id}`}
                                                errors={errors}
                                                placeholder="Select Status"
                                                // closeOnOutsideClick={true}
                                                handleOnChange={(data: any) => {
                                                  console.log(data.value);
                                                }}
                                                disabled={true}
                                                defaultValue={call?.status}
                                              />
                                            )}
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </TableCell>
                                );
                              case "multiple":
                                return (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                  >
                                    <ul className="multipleList">
                                    {Array.isArray(value) &&
                                      value.map((name: any, index: any) => {
                                        return (
                                          <li
                                            key={index}
                                          >
                                            {name}
                                          </li>
                                        );
                                      })}
                                      </ul>
                                  </TableCell>
                                );
                              default:
                                return (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                  >
                                    <div className="flex tableCopyRow alignCenter justifySpaceBetween">
                                      <span>{value ?? "-"}</span>
                                      <span>
                                        {value === "-"
                                          ? " "
                                          : value === row?.phone
                                          ? row?.copyPhone
                                            ? row?.copyPhone
                                            : ""
                                          : ""}
                                      </span>
                                    </div>
                                  </TableCell>
                                );
                            }
                          })}
                        </TableRow>
                      )}
                    </>
                  );
                }
              })
            ) : search.length > 0 ? (
              <TableRow
                className="noDataRow"
                hover
                role="checkbox"
                tabIndex={-1}
              >
                <TableCell colSpan={columns.length}>
                  {emptySearchMetaData && (
                    <EmptyPlaceholder
                      icon={emptySearchMetaData.icon}
                      title={emptySearchMetaData.title}
                      description={emptySearchMetaData.description}
                      button1={emptySearchMetaData.button1}
                      button2={emptySearchMetaData.button2}
                    />
                  )}
                </TableCell>
              </TableRow>
            ) : (
              <TableRow
                className="noDataRow"
                hover
                role="checkbox"
                tabIndex={-1}
              >
                <TableCell colSpan={columns.length}>
                  {emptyTableMetaData && (
                    <EmptyPlaceholder
                      icon={emptyTableMetaData.icon}
                      title={emptyTableMetaData.title}
                      description={emptyTableMetaData.description}
                      button1={emptyTableMetaData.button1}
                    />
                  )}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default CustomTable;
