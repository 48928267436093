import { deleteAPI, postAPI } from "../../apis/apis";

export const createStripeCoupon  = async (payload:any) => {
    return postAPI ({
        url:
          process.env.REACT_APP_BASE_URL + `/admin/stripe-coupons`,
        headers: {},
        data: payload,
      });
  };

  export const deleteStripeCoupon  = async (id:any) => {
    return deleteAPI ({
        url:
          process.env.REACT_APP_BASE_URL + `/admin/stripe-coupons/${id}`,
        headers: {},
        data: {},
      });
  };