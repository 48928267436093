export const refundMethod = [
    { label: "Partial", value: "partial" },
    { label: "Full", value: "full"},
  ];

  export const subscriptionTypeOptions = [
    { label: "General access", value: "general_access" },
    { label: "Credit access", value: "credit_access"},
    { label: "VA access", value: "va_access"},
  ];

  export const PAUSE_SUBSCRIPTION_DAYS_OPTIONS = [
    { value: 7, label: "7 Days" },
    { value: 15, label: "15 Days" },
    { value: 30, label: "30 Days" },
  ];