import { useState } from "react";
import {  useSelector } from "react-redux";
import Phone from "../../../../assets/icons/phone";
import EmptyPlaceholder from "../../../../atoms/EmptyPlacholder";
import ShadowBox from "../../../../components/shadowBox/ShadowBox";
import {
  copyToClipboard,
  formatPhoneNumber,
  getDateDifferenceInWords,
} from "../../../../utils/utils";
import { ContactInfoItemType, Schedule } from "../../property.type";
import styles from "./OverviewTab.module.scss";
import BlueTick from "../../../../assets/icons/blueTick";
import SearchReflation from "../../../../assets/icons/searchReflation";
import { MenuItem, Tooltip } from "@mui/material";
import Copy from "../../../../assets/icons/copy";
import FilterDropdown from "../../../../organisms/filterDropdown/FilterDropdown";
import Trash from "../../../../assets/icons/trash";
import Message from "../../../../assets/icons/message";
import { getPropertyDetailsData  } from "../../selector";
import PhoneV2 from "../../../../assets/icons/phoneV2";
import CallGradient from "../../../../assets/icons/CallGradient";


function calculatePaymentPerPeriod(
  principal: number,
  annualInterestRate: number,
  totalYears: number,
  isMonthly: boolean
) {
  const periodsPerYear = isMonthly ? 12 : 1;
  const totalPeriods = totalYears * periodsPerYear;
  const periodInterestRate = annualInterestRate / periodsPerYear;
  const paymentPerPeriod =
    principal *
    (periodInterestRate /
      (1 - Math.pow(1 + periodInterestRate, -totalPeriods)));
  if (!isFinite(paymentPerPeriod) || isNaN(paymentPerPeriod)) {
    return 0;
  }

  return paymentPerPeriod;
}

const calculateAmortizationSchedule = (
  principal: number,
  annualInterestRate: number,
  totalYears: number,
  isMonthly: boolean
) => {
  const periodsPerYear = isMonthly ? 12 : 1;
  const totalPeriods = totalYears * periodsPerYear;
  const periodInterestRate = annualInterestRate / periodsPerYear;
  const paymentPerPeriod = calculatePaymentPerPeriod(
    principal,
    annualInterestRate,
    totalYears,
    isMonthly
  );

  const generateSchedule = (
    period: number,
    balance: number,
    schedule: Schedule[]
  ): Schedule[] => {
    if (period > totalPeriods || balance <= 0) {
      return schedule;
    }

    const interestForPeriod = balance * periodInterestRate;
    let principalForPeriod = paymentPerPeriod - interestForPeriod;
    // Preventing principalForPeriod from going negative due to rounding in the final period
    principalForPeriod =
      principalForPeriod > balance ? balance : principalForPeriod;
    const endingBalance = balance - principalForPeriod;

    schedule.push({
      period: period,
      totalPayment: parseFloat(paymentPerPeriod.toFixed(2)),
      interest: parseFloat(interestForPeriod.toFixed(2)),
      principal: parseFloat(principalForPeriod.toFixed(2)),
      endingBalance: parseFloat(endingBalance.toFixed(2)),
    });

    return generateSchedule(period + 1, endingBalance, schedule);
  };

  return generateSchedule(1, principal, []);
};

interface OverviewTabsProps {
  handleClickSkipTrace?: () => void;
  handleClickAddContact?: () => void;
}

const OverviewTabs = ({
  handleClickSkipTrace,
  handleClickAddContact,
}: OverviewTabsProps) => {
  const propertyDetails = useSelector((state) => getPropertyDetailsData(state));
  const contactInfo = propertyDetails.phones as ContactInfoItemType[];
  const emails = propertyDetails.emails;
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [showMenu, setShowMenu] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);

  const handleMenu = (event: any, id: string) => {
    setMenuAnchorEl(event.currentTarget);
    setShowMenu(!showMenu);
    setSelectedItemId(id);
  };

  const handleMenuClose = () => {
    setShowMenu(false);
    setMenuAnchorEl(null);
    setSelectedItemId(null);
  };

  return (
    <div
    className={` ${styles.contactInfo} ${
      contactInfo?.length > 0 || emails?.length > 0 ? `` : styles.nodata
    }`}
  >
          {/* NOTE: I removed the `skipButton` param from the above <ShadowBox /> as we're temporarily hiding this button until it is implemented. */}
 
          <div className={` ${styles.contactInfo__list}`}>
          <h2>Contact Information</h2>
          {contactInfo?.length > 0 || emails?.length > 0 ? (
            <div
              className={` ${styles.contactInfo__listMain} dflex alignStart`}
            >
              {contactInfo?.length > 0 && (
                <div className={` ${styles.contactInfo__phoneList} dflex`}>
                  {contactInfo?.map((item: any, count) => (
                    <div
                      className={`flex alignCenter ${styles.contactInfo__box}`}
                      key={item.id}
                    >
                      <figure>
                        <PhoneV2 /> <label>Phone {count + 1}</label>
                      </figure>
                      <figcaption>
                        <div
                          className={`flex alignCenter ${styles.contactInfo__dotMenu}`}
                        >
                          <a href={`tel:${item.number}`}>
                            {formatPhoneNumber(item.number)}
                          </a>
                          <Tooltip title={"Copy Phone Number"}>
                            <span
                              role="link"
                              className="copy-icon"
                              onClick={(e: any) => {
                                e.stopPropagation();
                                copyToClipboard(item.number);
                              }}
                            >
                              <Copy />
                            </span>
                          </Tooltip>

                          {(item?.phoneAttempts?.length > 0 ||
                            item?.createdBy) && (
                            <span
                              className={` ${styles.contactInfo__blueTick}`}
                            >
                              <BlueTick />
                            </span>
                          )}
                     
                     
                        </div>
                        {/* <p>
                                                Added{" "}
                                                {getDateDifferenceInWords(new Date(item.createdAt))}
                                            </p> */}
                      </figcaption>
              
                    </div>
                  ))}
                </div>
              )}
              <div className={` ${styles.contactInfo__emailList} dflex`}>
                {emails.map((item: any) => (
                  <div
                    className={`flex alignCenter ${styles.contactInfo__box} ${styles.emailBox}`}
                    key={item.id}
                  >
                    <figure>
                      <Message /> <label>Email</label>
                    </figure>
                    <figcaption>
                      <div
                        className={`flex alignCenter ${styles.contactInfo__dotMenu}`}
                      >
                        <a
                          className={`flex alignCenter ${styles.contactInfo__email}`}
                          href={`mailto:${item.email}`}
                        >
                          {item.email}
                        </a>
                        <Tooltip title={"Copy Email Address"}>
                          <span
                            role="link"
                            className="copy-icon"
                            onClick={(e) => {
                              e.stopPropagation();
                              copyToClipboard(item.email);
                            }}
                          >
                            <Copy />
                          </span>
                        </Tooltip>

                        {item?.createdBy && (
                          <span className={` ${styles.contactInfo__blueTick}`}>
                            <BlueTick />
                          </span>
                        )}
                     
                     
                      </div>
                      {/* <p>
                                                Added{" "}
                                                {getDateDifferenceInWords(new Date(item.createdAt))}
                                            </p> */}
                    </figcaption>
              
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <>
              <div className={`${styles.contactInfo__blared}`}>
                <div
                  className={` ${styles.contactInfo__listMain} dflex alignStart`}
                >
                  <div className={` ${styles.contactInfo__phoneList} dflex`}>
                    <div
                      className={`flex alignCenter ${styles.contactInfo__box}`}
                    >
                      <figure>
                        <PhoneV2 /> <label>Phone 1</label>
                      </figure>
                      <figcaption>-</figcaption>
                    </div>
                    <div
                      className={`flex alignCenter ${styles.contactInfo__box}`}
                    >
                      <figure>
                        <PhoneV2 /> <label>Phone 2</label>
                      </figure>
                      <figcaption>-</figcaption>
                    </div>
                    <div
                      className={`flex alignCenter ${styles.contactInfo__box}`}
                    >
                      <figure>
                        <PhoneV2 /> <label>Phone 3</label>
                      </figure>
                      <figcaption>-</figcaption>
                    </div>
                    <div
                      className={`flex alignCenter ${styles.contactInfo__box}`}
                    >
                      <figure>
                        <PhoneV2 /> <label>Phone 4</label>
                      </figure>
                      <figcaption>-</figcaption>
                    </div>
                    <div
                      className={`flex alignCenter ${styles.contactInfo__box}`}
                    >
                      <figure>
                        <PhoneV2 /> <label>Phone 5</label>
                      </figure>
                      <figcaption>-</figcaption>
                    </div>
                  </div>
                  <div className={` ${styles.contactInfo__emailList} dflex`}>
                    <div
                      className={`flex alignCenter ${styles.contactInfo__box} ${styles.emailBox}`}
                    >
                      <figure>
                        <Message /> <label>Email</label>
                      </figure>
                      <figcaption></figcaption>
                    </div>
                    <div
                      className={`flex alignCenter ${styles.contactInfo__box} ${styles.emailBox}`}
                    >
                      <figure>
                        <Message /> <label>Email</label>
                      </figure>
                      <figcaption></figcaption>
                    </div>
                  </div>
                </div>
              </div>
              <EmptyPlaceholder
                icon={<CallGradient />}
                title={"It’s a ghost town here"}
                description="Unlock the details—skip-trace this property to reveal contact info!"
                smallSize={true}
                bgGray={true}
                extraStyles={{ maxWidth: "100%", paddingTop:"0", minHeight:"100%" }}
                blared
              />
            </>
          )}
        </div>
          </div>
  );
};
export default OverviewTabs;
