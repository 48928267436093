import { ClickAwayListener } from "@mui/material";
import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from "draft-js";
import { useCallback, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CommentIcon from "../../assets/icons/commentIcon";
import Plus from "../../assets/icons/plus";
import Button from "../../atoms/Button";
import EmptyPlaceholder from "../../atoms/EmptyPlacholder";
import Loader from "../../atoms/Loader";

import styles from "./Chat.module.scss";
import Message from "./Message";
import { tabTitle } from "../../pages/propertyDetail/property.enum";

const SAMPLE_MESSAGE = {
  dateTime: "Today 2:20pm",
  body: "Spoke with John Smith about his property at 123 Maple Street. He is considering selling due to relocation for a new job. Asking price is around $250,000, with some flexibility. Plan to follow up in 5 days, send more info, and potentially schedule a meeting. I set my follow up reminder to call back here in 5 days. Could be a great opportunity.",
  createdByName: "Andi Lane",
};

const Chat = ({
  chatLoading,
  sendLoading,
  messages,
  title = "My Notes",
  handleNextPage,
  totalCount,
  smartSummary,
    displayTitle="",
  displayDescription=""
}: {
  chatLoading: boolean;
  sendLoading: boolean;
  messages: any[];
  title: string;
  handleNextPage: Function;
  totalCount: number;
  smartSummary?: boolean;
  displayDescription?:string
  displayTitle?: string
}) => {
  const [addNote, setAddNote] = useState(false);
  const param = useParams();
  const [editorVisible, setEditorVisible] = useState(false);
  const [selectedCommentId, setSelectedCommentId] = useState<string>("");
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  
  const handleEditMenuItemClick = useCallback((message: any) => {
    const { comment, body } = message;
    const { contentBlocks, entityMap } = convertFromHTML(comment || body);

    const newEditorState = EditorState.createWithContent(
      ContentState.createFromBlockArray(contentBlocks, entityMap)
    );
    setEditorState(newEditorState);
    setEditorVisible(true);
    setAddNote(true);
  }, []);

  const handleEditorStateChange = (newState: EditorState) => {
    setEditorState(newState);
  };
  return (
    <div className={` ${styles.chat} ${
        smartSummary ? styles.smartChat : ""
      }`}>
      <div className="wrapper bottom-zero">
        <div
          className={` ${styles.chat__notes} ${
            editorVisible ||
            messages.length === 0 ||
            messages.length === undefined ||
            addNote
              ? styles.active
              : ""
          } `}
        >
          <div
            className={` ${styles.chat__header} ${
              smartSummary ? styles.smartSummary : ""
            }   dflex justifySpaceBetween`}
          >
            <h2>{title}</h2>
          </div>
          {!chatLoading && (
            <div className={` ${styles.chat__list} ${
                smartSummary ? styles.smartSummaryList : ""
              }`} id="scrollableDiv">
              {(editorVisible ||
                messages.length === 0 ||
                messages.length === undefined) &&
                (!chatLoading || addNote) && (
                  <>
                    {[...Array(3)].map((_, index) => (
                      <Message
                        handleClick={() => {}}
                        title={title}
                        message={SAMPLE_MESSAGE}
                        setSelectedCommentId={setSelectedCommentId}
                        dateTime={SAMPLE_MESSAGE.dateTime}
                        body={SAMPLE_MESSAGE.body}
                        createdByName={SAMPLE_MESSAGE.createdByName}
                        id={`${index}`}
                        alignRight={false}
                      />
                    ))}
                  </>
                )}
              {messages.length > 0 && (
                <div>
                  <InfiniteScroll
                    dataLength={messages?.length}
                    next={() => handleNextPage && handleNextPage()}
                    hasMore={messages.length < totalCount}
                    scrollableTarget="scrollableDiv"
                    loader={
                      <div className="table-loader">
                        <Loader size={20}></Loader>
                      </div>
                    }
                  >
                    <>
                      {messages?.map((message) => (
                        <>
                          <Message
                            key={`${message?.id}_chat`}
                            handleClick={handleEditMenuItemClick}
                            title={title}
                            dateTime=//   TodayDate !== // {
                            //   new Date(
                            //     message?.createdAt ?? message?.CreatedAt
                            //   ).setHours(0, 0, 0, 0)
                            //     ? formatDate(
                            //         message?.createdAt,
                            //         "MMM dd, yyyy  hh:mm aaaaa'm'"
                            //       )
                            //     : `Today ${formatDate(
                            //         message?.createdAt,
                            //         "hh:mm aaaaa'm'"
                            //       )}`
                            // }
                            {new Date(message?.createdAt).toLocaleString()}
                            body={message?.body}
                            createdByName={message?.createdByName}
                            id={`${message?.id}`}
                            setSelectedCommentId={setSelectedCommentId}
                            alignRight={message?.alignRight || false}
                            message={message}
                          />
                        </>
                      ))}
                    </>
                  </InfiniteScroll>
                </div>
              )}
            </div>
          )}
        </div>
        {!chatLoading &&
          (addNote ||
            messages.length === 0 ||
            messages.length === undefined) && (
            
              <div className={` ${styles.chat__addNote} `}>
                <ClickAwayListener
                  onClickAway={() => {
                    setEditorVisible(false);
                    setAddNote(false);
                    if (selectedCommentId) {
                      setEditorState(EditorState.createEmpty());
                      setSelectedCommentId("");
                    }
                  }}
                >
                  {tabTitle.AISUMMARY !== title ? (
                    <div className={` ${styles.chat__addNoteTextarea} `}>
                      
                      
                    </div>
                  ) : (
                    <> </>
                  )}
                </ClickAwayListener>
                {!editorVisible &&
                  (messages.length === 0 || messages.length === undefined) && (
                    <EmptyPlaceholder
                      icon={<CommentIcon />}
                      title={displayTitle}
                      description={displayDescription}
                      bgGray={true}
                      extraStyles={{ maxWidth: "100%", minHeight:"100%" }}
                      blared
                    />
                  )}
              </div>
            
          )}
        {chatLoading && (
          <div
            className={` ${styles.chat__loader} flex alignCenter justifyCenter`}
          >
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
};
export default Chat;
