import { initialState } from "./reducer";

const getStateData = (state: any) => state["logAttempt"] || initialState;

export const getLogAttemptList=(state:any)=>{
  const reducer = getStateData(state);
  return reducer.logAttemptList;
}


export const getLogAttemptListLoading = (state: any) => {
  const reducer = getStateData(state);
  return reducer.logAttemptListLoading;
};

export const getLogAttemptListCount = (state: any) => {
  const reducer = getStateData(state);
  return reducer.logAttemptListCount;
};
