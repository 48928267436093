import React, { useContext, useState } from "react";
import { ROUTES } from "../../constant/constant";
import { NavLink, useNavigate } from "react-router-dom";
import Button from "../../atoms/Button";
import { defaultLogout } from "../../organisms/auth/auth";
import styles from "./SideBar.module.scss";
import Logo from "../../atoms/Logo";
import List from "../../assets/icons/list";
import User from "../../assets/icons/user";
import Setting from "../../assets/icons/setting";
import Logout from "../../assets/icons/logout";
import ProfileMenu from "../../pages/profile/ProfileMenu";
import {
    MEDIA_TYPES,
    MediaContext,
    MediaContextProps,
  } from "../../context/MediaContext";
import { Call, Discount } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { PermissionListEnum } from "../../pages/rolesAndPermission/enum";
import { useSelector } from "react-redux";
import { getProfileInfo } from "../../pages/profile/selector";
const SideBar = () => {
    const { media } = useContext(MediaContext) as MediaContextProps;
  const profileInfo = useSelector((state) => getProfileInfo(state));
    const navigate = useNavigate();

    const handleHomeNavigate=()=>{
      navigate("/organisation");
    }
  return (
    <div className={`sidebar ${styles.sideBar} `}>
            <div className={`${styles.sideBar__logo} `} onClick={()=>handleHomeNavigate()}>
        <Logo />
      </div>
      <div className={`${styles.sideBar__nav} `}>
        <ul>
          <li>
            <NavLink to={ROUTES.ORGANIZATION}>
              <span>
                <List />
              </span>
              <figcaption>
              Organisation
              </figcaption>
            </NavLink>
          </li>
          <Tooltip title={profileInfo?.permissions?.includes(PermissionListEnum.GET_ADMIN_USERS) ? "": "You don't have permission to access this."}>
          <li className={profileInfo?.permissions?.includes(PermissionListEnum.GET_ADMIN_USERS) ? " " : `${styles.sideBardisable}`}>
            <NavLink to={ROUTES.USERS}>
              <span>
                <User />
              </span>
              <figcaption>
              Users
              </figcaption>
            </NavLink>
          </li>
          </Tooltip>
          <Tooltip title={profileInfo?.permissions?.includes(PermissionListEnum.GET_ROLES) ? "": "You don't have permission to access this."}>
          <li className={profileInfo?.permissions?.includes(PermissionListEnum.GET_ROLES) ? " " : `${styles.sideBardisable}` }>
            <NavLink to={ROUTES.ROLEANDPERMISSION}>
              <span>
                <Setting />
              </span>
              <figcaption>
              Roles and Permissions
              </figcaption>
            </NavLink>
          </li>
          </Tooltip>
          <Tooltip title={profileInfo?.permissions?.includes(PermissionListEnum.GET_BOT_WORKER) ? "": "You don't have permission to access this."}>
          <li 
          className={profileInfo?.permissions?.includes(PermissionListEnum.GET_BOT_WORKER) ? " " : `${styles.sideBardisable}` } 
          >
            <NavLink to={ROUTES.BOTCALLING}  >
              <span>
                <Call />
              </span>
              <figcaption>
              AI Bot Calling
              </figcaption>
            </NavLink>
          </li>
          </Tooltip>
          <Tooltip title={profileInfo?.permissions?.includes(PermissionListEnum.GET_BOT_WORKER) ? "": "You don't have permission to access this."}>
          <li 
          className={profileInfo?.permissions?.includes(PermissionListEnum.GET_BOT_WORKER) ? " " : `${styles.sideBardisable}` } 
          >
            <NavLink to={ROUTES.COUPON}  >
              <span>
                <Discount />
              </span>
              <figcaption>
             Coupon
              </figcaption>
            </NavLink>
          </li>
          </Tooltip>
        </ul>
        <div className={`${styles.sideBar__bottom} `}>
        { media !== MEDIA_TYPES.DESKTOP && (
             <ProfileMenu/>
            )}
       
        <Button
          className="primary"
          label="Logout"
          prefix={<Logout />}
          action={defaultLogout}
        ></Button>
        </div>
  
      </div>
    </div>
  );
};

export default SideBar;
