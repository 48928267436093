import { useState } from "react";
import { Controller } from "react-hook-form";
import { InputProps } from "../models/input.interface";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

const Input = ({
  type,
  label,
  register,
  name,
  placeholder,
  errors,
  control,
  min,
  onKeyDown,
  readOnly,
  prefix,
  defaultValue
}: InputProps) => {

  const formatValue = (value:any) => {
    return value.replace(/,/g, ''); 
  };


  const defaultMaskOptions = {
    prefix: "",
    suffix: "",
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ",",
    allowDecimal: true,
    decimalSymbol: ".",
    decimalLimit: 2, // how many digits allowed after the decimal
    integerLimit: 9, // limit length of integer numbers
    allowNegative: false,
    allowLeadingZeroes: false,
  };
  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
  });

  const getInputByType = () => {
    switch (type) {
      case "currency": {
        return (
          control && (
            <Controller
              render={({ field }) => (
                <MaskedInput
                  mask={currencyMask}
                  className={`${
                    errors && (errors?.[name] || errors?.message)
                      ? "inputError"
                      : ``
                  }`}
                  type="text"
                  placeholder={placeholder}
                  {...field}
                  readOnly={readOnly}
                  onChange={(e) => field.onChange(formatValue(e.target.value))}
                />
              )}
              
              name={name}
              control={control}
            />
          )
        );
      }
      case "numeric": {
        return (
          control && (
            <Controller
              render={({ field }) => (
                <input
                  type="number"
                  placeholder={placeholder}
                  className={`${
                    errors && (errors?.[name] || errors?.message)
                      ? "inputError"
                      : ``
                  }`}
                  onChange={(e) => {
                    const re = /^-?\d*\.?\d{0,2}$/;
                    if (re.test(e.target.value)) {
                      field.onChange(e.target.value);
                    }
                  }}
                  value={field.value}
                />
              )}
              name={name}
              control={control}
            />
          )
        );
      }
      case "alphaNumeric": {
        return (
          control && (
            <Controller
              render={({ field }) => (
                <input
                  type="text"
                  placeholder={placeholder}
                  className={`${
                    errors && (errors?.[name] || errors?.message)
                      ? "inputError"
                      : ``
                  }`}
                  onChange={(e) => {
                    const re = /^[A-Z0-9a-z]*$/;
                    if (re.test(e.target.value)) {
                      field.onChange(e.target.value);
                    } else if (e.target.value.length === 1) {
                      field.onChange("");
                    }
                  }}
                  value={field.value}
                />
              )}
              name={name}
              control={control}
            />
          )
        );
      }
      case "number" :{
        return (
          <input
            className={`${
              errors && (errors?.[name] || errors?.message) ? "inputError" : ``
            }`}
            type={type}
            {...register(name)}
            placeholder={placeholder}
            min={min}
            onKeyDown={(e) => {
              if (onKeyDown) onKeyDown(e);
              if (e.key === '.' || e.key === 'e' || e.key === '+' || e.key === '-') {
                e.preventDefault();
              }
            }}
            onPaste={(e) => {
              const pasteData = (e.clipboardData).getData('text');
              if (pasteData.includes('.')) {
                e.preventDefault();
              }
            }}
            readOnly={readOnly}
            defaultValue={defaultValue}
          />
        );
      }
      default: {
        return (
          <input
            className={`${
              errors && (errors?.[name] || errors?.message) ? "inputError" : ``
            }`}
            type={type==="uppercase" ? "text" : type}
            {...register(name,{
              onChange: (e) => {
                if(type==="uppercase"){
                e.target.value = e.target.value.toUpperCase()}
              },
            })}
            placeholder={placeholder}
            min={min}
            onKeyDown={(e)=>onKeyDown&&onKeyDown(e)}
            readOnly={readOnly}
            defaultValue={defaultValue}
          />
        );
      }
    }
  };
  return (
    <div className={`inputGroup`}>
      {label && <label className="label">{label}</label>}
      <span
        className={`inputGroup__input ${
          type === "password" ? `inputPassword` : ``
        } ${prefix ? `prefixMain` : ``}`}
      >
           {prefix && (
          <span role="link" className={`prefix`}>
            {prefix}
          </span>
        )}
        {getInputByType()} 
      </span>
      
          {errors && errors?.[name] && (
            <span className="error">{errors?.[name]?.message}</span>
          )}
          {errors && errors?.message && (
            <span className="error">{errors?.message}</span>
          )}
        
    </div>
  );
};

export default Input;
