import { Campaign, History, LeaderboardSharp, ListAlt } from "@mui/icons-material";
import User from "../../assets/icons/user";
import { PermissionListEnum } from "../rolesAndPermission/enum";
import CallBackLater from "../../assets/icons/call";


export const tabs = [
    { path: 'campaigns', label: 'Campaigns',icon:Campaign,permissions:PermissionListEnum.GET_CAMPAIGN_DETAILS},
    { path: 'leads', label: 'Leads' ,icon:LeaderboardSharp,permissions:PermissionListEnum.GET_LEAD },
    { path: 'list', label: 'List',icon:ListAlt ,permissions:PermissionListEnum.GET_LIST },
    { path: 'billing-history', label: 'Billing History',icon:History , permissions:PermissionListEnum.PAYMENT_LEVEL_ACCESS},
    { path: 'user-detail', label: 'Manage Team',icon:User, permissions:PermissionListEnum.GET_ORGANISATION_DETAILS },
   { path: 'log-Attempt', label: 'Log Attempt',icon:CallBackLater, permissions:PermissionListEnum.GET_ORGANISATION_DETAILS }
  ];