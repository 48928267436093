import { Column } from "../../models/table.interface";

export const columns: Column[] = [
  {
    id: "name",
    label: "Name", 
    minWidth: 50,
    type: "",
  },
  {
    id: "email",
    label: "Email",
    minWidth: 30,
    type: "",
  },
  {
    id: "attempt",
    label: "Attempt",
    minWidth: 20,
    type: "",
  },
  {
    id: "address",
    label: "Address",
    minWidth: 220,
    type: "",
  },
  { id: "createdAt", label: "Created At", minWidth: 220, type: "" },
  { id: "summary", label: "Summary", minWidth: 350, type: "summary" },
  { id: "callStatus", label: "Call Status", minWidth: 300, type: "callStatus" },
];

export const callAttemptsOptions = {
  "Left Voicemail": "LeftVoiceMail",
  "Disconnected": "Disconnected",
  "Wrong Number": "WrongNumber",
  "Do Not Pick Up": "DoNotPickUp",
  "Hung-up": "HungUp",
  "Not Interested": "NotInterested",
  "DNC": "DNC",
  "Call Back Later": "CallBackLater",
  "Verified": "Verified",
} as const;