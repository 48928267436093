import React, { useEffect, useState } from "react";
import Button from "../../atoms/Button";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Input from "../../atoms/Input";
import { registerFormSchema } from "./validations";
import MaskedInput from "../../atoms/MaskedInput";
import { getProfileInfo } from "../../pages/profile/selector";
import { useSelector } from "react-redux";
import { sendActivityLog } from "../auth/service";
import { useParams } from "react-router-dom";
import myAuditLogs from "@ottoleads/ottoleads_log_aggregation";
import DropDown from "../../atoms/Dropdown";
import CustomSwitch from "../../atoms/CustomSwitch";
import { getOrgUserRoleList } from "../../pages/userDetail/selector";
import { getOrganization } from "../../pages/organization/selector";

const AddUser = ({ handleClose, onSubmitDetail, loading }: any) => {
  const {
    formState: { errors },
    register,
    control,watch,
    handleSubmit,setValue
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(registerFormSchema),
    defaultValues: {
      isPaidSeat: false,
      name:"",
      email:"",
      phone:""
    },

  });
  const { orgId } = useParams();

  const profileInfo = useSelector((state) => getProfileInfo(state));
  const roleList = useSelector((state) => getOrgUserRoleList(state));
  const orgDetail = useSelector((state) => getOrganization(state));
  const [roleListOptions, setRoleListOptions] = useState([]);

  useEffect(() => {
    const options = roleList?.filter((list: any) => orgDetail?.isVaSubscribed || list !== 'VA').map((list: any) => ({
      value: list,
      label: list,
    }));
    setRoleListOptions(options);
  }, [roleList,orgDetail]);

  useEffect(()=>{
    if(watch("userRoleName") ==="VA"){
      setValue("isPaidSeat", true)
    }
  },[watch("userRoleName")])

  useEffect(() => {
    const logAuditData = async () => {
      const attributes = {
        action: `${
          profileInfo?.nameFirst + " " + profileInfo?.nameLast
        } is trying to add new user to organisation ${orgId}`,
        userId: profileInfo?.id,
        uuid: "99",
        email: profileInfo?.email,
        roleName: profileInfo?.roleName,
      };
      try {
        const result = await myAuditLogs(attributes);
        console.log(result);
        try {
          const res = await sendActivityLog({
            logType: `Trying to add new user to organisation ${orgId}`,
            jsonData: result,
          });
          console.log(res);
        } catch (error) {
          console.error("Error logging audit data:", error);
        }
      } catch (error) {
        console.error("Error logging audit data:", error);
      }
    };
    logAuditData();
  }, [profileInfo, orgId]);

  return (
    <>
      <Input
        type="text"
        label="Name*"
        register={register}
        name="name"
        placeholder="Enter user name"
        errors={errors}
      ></Input>
      <Input
        type="email"
        label="Email*"
        register={register}
        name="email"
        placeholder="Enter user email"
        errors={errors}
      ></Input>
      <MaskedInput
        placeholder="Enter user phone number"
        label="Phone"
        name="phone"
        register={register}
        errors={errors}
        mask={"999-999-9999"}
      ></MaskedInput>
      <DropDown
        options={roleListOptions}
        label="Role*"
        control={control}
        name="userRoleName"
        errors={errors}
        placeholder="Select role"
      ></DropDown>

      <div className="inputGroup flex align-center gap-10">
        <CustomSwitch
          control={control}
          name="isPaidSeat"
          defaultChecked={false}
          disabled={watch("userRoleName") ==="VA"}
        />
        <label className="label mb-0"> Is this complimentary?</label>
      </div>

      <div className="authStyle__footer">
        <Button
          label="Send Invite"
          className="primary full xl"
          action={handleSubmit(onSubmitDetail)}
          clicked={loading}
          disabled={loading}
        ></Button>
      </div>
    </>
  );
};

export default AddUser;
