import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Chat from "../../organisms/chat/Chat";
import { PropertyDetailsParentIdType } from "../propertyDetail/property.enum";
import { ToastType } from "../../molecules/toaster/enum";
import { addToast } from "../../molecules/toaster/toastSlice";
import { getPropertyAISummary } from "../propertyDetail/service";
import { getPropertyDetailsData } from "../propertyDetail/selector";
import { useParams } from "react-router-dom";

const SmartSummaryTab = () => {
   const { orgId } = useParams();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [addNotesLoading, setAddNotesLoading] = useState(false);
  const [messages, setMessages] = useState<any[]>([]);
  const propertyDetails = useSelector((state) => getPropertyDetailsData(state));
  const [pageNumber, setPageNumber] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const handleShowToast = (
    message: string,
    type: ToastType = ToastType.success
  ) => {
    dispatch(
      addToast({
        id: new Date().getTime(),
        message: message,
        type: type,
      })
    );
  };

  const fetchMessages = useCallback(
    async (id: string | number, pageNumber = 1) => {
      try {
        if(pageNumber===1){
          setIsLoading(true);
        }
        const data = {
          index: pageNumber,
          size: 5,
        };
        const response = await getPropertyAISummary(
          id,
          orgId,
          data,
          PropertyDetailsParentIdType.LEAD_ID
        );
        
        const newMessage = response?.data?.data?.map((message: any) => ({
          ...message,
          createdAt: message?.created_at || "",
          body: message?.summary || "",
          alignRight:
            false,
          createdByName:
            false
              ? "You"
              : "Ottoleads",
          id: message.id,
        }));

        setTotalCount(+(response?.data?.total || 0));
        setMessages((messages) => [...(pageNumber===1? [] :messages), ...(newMessage || [])]);
      } catch (error: unknown) {
        handleShowToast(
          (error as any)?.response?.data?.message || "Something went wrong!",
          ToastType.error)
      } finally {
        setIsLoading(false);
      }
    },
    [orgId]
  );
   

  useEffect(() => {
    if (propertyDetails?.leadId) {
      fetchMessages(propertyDetails?.leadId, pageNumber);
    }
  }, [propertyDetails?.leadId, pageNumber,orgId]);

  return (
    <Chat
      chatLoading={isLoading}
      sendLoading={addNotesLoading}
      messages={messages}
   
      title="AI Smart Summary"
      displayTitle=""
      displayDescription=""
      totalCount={totalCount}
   
      smartSummary
      handleNextPage={() => setPageNumber((prev) => prev + 1)}
    />
  );
};
export default SmartSummaryTab;
